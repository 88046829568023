import './App.css';
import * as React from 'react';
import LogRocket from 'logrocket';
import Home from './components/Home/Home';
import { useState, useEffect } from 'react';
import { TOAST_TYPE } from './utils/constants';
import PrivateRoutes from './utils/PrivateRouter';
import SignedInRouter from './utils/SignedInRouter';
import { useUserContext } from './context/UserContext';
import LandingPage from './pages/LandingPage/LandingPage';
import RedirectHomeRouter from './utils/RedirectHomeRouter';
import PageNotFound from './components/PageNotFound/PageNotFound';
import OpenLayersPage from './pages/OpenLayersPage/OpenLayersPage';
import OtpVerifiedPage from './pages/OtpVerifiedPage/OtpVerifiedPage';
import UploadOrderPage from './pages/UploadOrderPage/UploadOrderPage';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import TeamManagement from './components/TeamManagement/TeamManagement';
import OpenLayersIdPage from './pages/OpenLayersIdPage/OpenLayersIdPage';
import AccessDeniedPage from './pages/AccessDeniedPage/AccessDeniedPage';
import OrganizationPage from './pages/OrganizationPage/OrganizationPage';
import UploadOrderDashboard from './pages/UploadOrderDashboard/UploadOrderDashboard';
import OrganizationSetting from './components/OrganizationSetting/OrganizationSetting';
import PageNotAuthorized from './components/PageNotAuthorized/PageNotAuthorized';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Register from './pages/Register/Register';
import SignIn from './pages/SignIn/SignIn';
import { Toaster } from 'react-hot-toast';
import OrderList from "./pages/OrderList/OrderList"
import Otp from "../src/pages/Otp/Otp"
import Organization from "../src/pages/Organization/Organization"
import SettingsPage from './pages/SettingsPage/SettingsPage';
import UserSetting from './components/UserSetting/UserSetting';
import ShareLayersIdPage from './pages/ShareOrderId/ShareLayersIdPage';
import PreviewOrderPage from './pages/PreviewOrder/PreviewOrderPage';
import CreditManagement from './components/CreditManagement/CreditManagement';



export default function App() {
  const location = useLocation();
  const url = window.location.href;
  const prod = process.env.REACT_APP_PROD;
  const role = localStorage.getItem('role');
  
  console.log("role", role)
  const { userInfo, selfCredits } = useUserContext();
  const [toasterData, setToasterData] = useState({});
  const [showToaster, setShowToaster] = useState(false);
  const pathDecider = location.pathname.substring(0, 12);

const fullName = userInfo
  ? `${userInfo.first_name || ""} ${userInfo.last_name || ""}`.trim()
  : "";
const email = userInfo ? userInfo.email : "";
const contactNumber = userInfo ? userInfo.contact_number : "";

useEffect(() => {
  LogRocket.init("mdt8me/mapnexa");
  if (email) {
    // Only try to identify if email is available
    LogRocket.identify(email, {
      name: fullName,
      email: email,
    });
  }
}, [email, fullName]);



  const handleSuccess = (type, message) => {
    setToasterData({ type: type, message: message });
    setShowToaster(true);
  };

  const handleError = (err) => {
    if (err.response.status === 500) {
      setToasterData({
        type: TOAST_TYPE.ERROR,
        message: 'Something went wrong',
      });
    } else {
      let error;
      if (err.response.data.errorDesc)
        error = err.response.data.errorDesc;
      else error = err.response.data.msg;
      setToasterData({ type: TOAST_TYPE.ERROR, message: error });
    }
    setShowToaster(true);
  };

  useEffect(() => {
    // if (url.includes(prod)) {
    if (url.includes(process.env.REACT_APP_DEV)) {
      window.analytics.page();
    }
  }, []);

  return (
    <div className="App">
      <Toaster />
      {pathDecider === '/share/order' ? (
        <Routes>
          <Route path="/share/order/:id" element={<ShareLayersIdPage />} />
        </Routes>
      ) : (
        <Routes>
          <Route element={<SignedInRouter />}>
            <Route
              path=""
              element={
                <SignIn/>
              }
            />
            <Route
              path="/register"
              element={
                <Register
                />
              }
            />
            <Route
              path="/forgot-password"
              element={
                <ForgotPassword
                />
              }
            />
            <Route
              path="/reset-password"
              element={
                <ResetPassword
                />
              }
            />
            <Route path="otp" element={<Otp />} />
          </Route>
          
          <Route element={<PrivateRoutes />}>
            {role === 'admin' || role === 'user' ? (
              <Route
                path="/"
                element={
                  <Home
                  />
                }
              >
                <Route path="/organization" element={<Organization />} />
                
                <Route path="/home" element={<LandingPage />} />
                <Route path="" element={<Navigate to="/home" />} />
                <Route path="/order" element={<OpenLayersPage />} />
                <Route
                  path="/order/:id"
                  element={
                    <OpenLayersIdPage/>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <OrderList
                    />
                  }
                />
                <Route path="/setting" element={<SettingsPage />}>
                  <Route
                    path="/setting/user"
                    element={
                      <UserSetting
                        handleError={handleError}
                        handleSuccess={handleSuccess}
                      />
                    }
                  />
                  <Route
                    path="/setting/org"
                    element={
                      <OrganizationSetting
                        handleError={handleError}
                        handleSuccess={handleSuccess}
                      />
                    }
                  />
                  {/* <Route path="/setting/team" element={<TeamManagement />} /> */}
                  <Route
                    path="/setting/credit"
                    element={
                      <CreditManagement
                        handleError={handleError}
                        handleSuccess={handleSuccess}
                      />
                    }
                  />
                  {/* <Route
                    path="/setting/feature"
                    element={
                      <FeatureManagement
                        handleError={handleError}
                        handleSuccess={handleSuccess}
                      />
                    }
                  /> */}
                </Route>
              </Route>
            ) : role === 'carto' ? (
              <Route>
                <Route
                  path="/home"
                  element={
                    <UploadOrderDashboard
                    />
                  }
                />
                <Route
                  path="/upload/order"
                  element={
                    <UploadOrderDashboard
                    />
                  }
                />
                <Route
                  path="/upload/order/:id/krypton"
                  element={
                    <UploadOrderPage
                    />
                  }
                />

<Route
                  path="/preview/order/:id/krypton"
                  element={
                    <PreviewOrderPage
                    />
                  }
                />

                <Route element={<PrivateRoutes />}>
                  <Route
                    path="/order/:id"
                    element={
                      <OpenLayersIdPage
                        handleError={handleError}
                        handleSuccess={handleSuccess}
                      />
                    }
                  />
                </Route>
                <Route render={() => <Navigate to="/upload/order" />} />
              </Route>
            ) : null}
            {/* <Route path="/4O4" element={<PageNotFound />} /> */}
            <Route path="/4O3" element={<PageNotAuthorized />} />
            <Route path="*" element={<RedirectHomeRouter />} />
            {/* <Route path="/access-denied" element={<AccessDeniedPage />} /> */}
          </Route>
        </Routes>
      )}
    </div>
  );
}
