import React, {useState, useEffect} from 'react'
import classes from "./OtpVerified.module.css"

import OtpLogo from "../../assets/otp_lock.svg"
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Button } from '@mui/material'
import { apiQueries } from '../../utils/ApiCall'
import toast from 'react-hot-toast'
import { SUCCESS_STATUS_CODE, FAILURE_STATUS_CODE } from '../../utils/constants'
import { useLocation, useNavigate } from "react-router-dom";
import VerifiedIcon from '@mui/icons-material/Verified';




function OtpVerified() {

    const [otp, setOtp] = useState('')
    const [email, setEmail] = useState(null)
    const navigate = useNavigate();
    

    useEffect(() => {
        const searchParams = new URLSearchParams(document.location.search)
        const user_email = searchParams.get("user_mail")
        if( user_email === null)
            navigate('/register')
        else{
            setEmail(user_email)
        }
      }, []);

      const loginRoute = () =>{
        navigate('/login')
      }

    const handleChange = (newValue) => {
        setOtp(newValue)
      }

    const resendOtp = () =>{
        const data = {"email" : email}
      apiQueries("POST", "user/otp/resend", data).then((res)=>{
        if(SUCCESS_STATUS_CODE.includes(res.status)){
            toast.success("OTP Sent on email")
        }
        else if(FAILURE_STATUS_CODE.includes(res.status)){
          toast.error(res.data.errorDesc)
        }     
      })

    }


  return (
    <div className={classes.OtpContainer}>
       <VerifiedIcon className={classes.VerifiedIcon}/>
        <div className={classes.OTPVerificationText}>
            Successful Account Verififcation 
        </div>
        <div className={classes.OtpMessage}>
        {`Your account has been successfully created, and our team is currently in the process of verifying your account. You can expect an update within the next hour. This verification process is in place to enhance data security and ensure that only genuine users have access to our platform, thereby safeguarding against potential malicious activities.`}
        </div>
        <div className={classes.LoginBtn} onClick={loginRoute}>Back to Login</div>
    </div>

  )
}

export default OtpVerified