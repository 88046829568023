import React from "react";
import "./SomethingWrong.css";
import { Link } from "react-router-dom";
import { ReactComponent as WrongIcon } from "../../assets/something-wrong.svg";

const SomethingWrong = () => {
  return (
    <div class="somthing-wrong-container">
      <div class="wrong-container-one">
        <div class="opps-div-one">
          <h1 class="opps">Ooops!</h1>
          <h3 class="something-wrong">Something looks wrong...</h3>
        </div>
        <div class="opps-div-two">
          <h5 class="">Try reloading the page.</h5>
        </div>
        <div className="something-wrong-buttons">
          <button
            class="link_404"
            onClick={() => window.location.reload(false)}
          >
            Reload
          </button>
          <a href="/home" class="link_404">
            Go to Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default SomethingWrong;
