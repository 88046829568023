import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import classes from "./UploadOrderDashboard.module.css"
import DashBoardStatusContainer from '../../components/DashBoardStatusContainer/DashBoardStatusContainer'
import UploadOrderTable from '../../components/UploadOrderFolder/UploadOrderFolder';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from "react-router-dom";

function UploadOrderDashboard() {
  const location = useLocation();

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handleLogout = () => {
    window.localStorage.removeItem('authToken');
    window.localStorage.removeItem('role');
    navigate("/login");
}

  return (
    <div className={classes.OuterContainer}>
      <div className={classes.LogoutContainer}>
        <ExitToAppIcon onClick={handleLogout}/>
      </div>
      <UploadOrderTable currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}/>
    </div>
  );
}

export default UploadOrderDashboard