import React, { useState, useEffect } from "react";
import classes from "./DropDown.module.css";

function DropDown({ featureGroup, setFeatureList, setFeatureSelected }) {
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    if (featureGroup.length) {
      setFeatureSelected(featureGroup[0]);
      setFeatureList(featureGroup[0].fetaure_list[0]);
    }
  }, [featureGroup]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    const selectedFeatureGroup = featureGroup.find(
      (group) => group.id === Number(event.target.value)
    );
    if (selectedFeatureGroup) {
      setFeatureList(selectedFeatureGroup.fetaure_list[0]);
      setFeatureSelected(selectedFeatureGroup);
    }
  };

  return (
    <div className={classes.FeatureGroupContainer}>
      <span>Select a feature Group</span>
      <select value={selectedOption} onChange={handleOptionChange}>
        {featureGroup.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default DropDown;
