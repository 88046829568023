import React, { useEffect, useState } from 'react';
import classes from "./MultiCheckBoxCustom.module.css"

const MultiCheckBoxCustom = ({ options, setFeatureCount, totalFeatureList, setCustomSelection, selection, setSelection }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if ((selectedOptions.length >0) && (selection==true)){
      console.log(selectedOptions,selectedOptions.length ,"selectedOptions")
      console.log("Removing everything")
      setSelectedOptions([])
    }
    
  },[selection]);
  const handleCheckboxChange = (optionValue) => {
    setSelection(false)
    console.log(selectedOptions, optionValue)
    if (selectedOptions.length >0){
      if (selectedOptions.includes(optionValue)) {
        setSelectedOptions(selectedOptions.filter(value => value !== optionValue));
      } else {
        setSelectedOptions([...selectedOptions, optionValue]);
      }
    }
    else{
      console.log("coming here")
      setSelectedOptions([optionValue]);
    }
    
    
  };

  useEffect(() => {
    setFeatureCount(selectedOptions.length)
    const selectFeatList = []
    for(let i =0; i<totalFeatureList.length; i++){
      if (selectedOptions.includes(totalFeatureList[i].id)){
        selectFeatList.push(totalFeatureList[i])
      }
    }
    setCustomSelection(selectFeatList)
  },[selectedOptions]);

  

  useEffect(() => {
    
    const selectedFeature = []
    if (options)
    options.map(singleOption =>{
      selectedFeature.push(singleOption.id)
    })
    setFeatureCount(selectedOptions.length)
    setSelectedOptions(selectedFeature);
  },[options]);

  return (
    <div className={classes.optionSelect}>
      <div>
        <h2>Polygon</h2>
        <div className={classes.listFeatures}>

        {totalFeatureList
          .filter(option => option.feature_type === "polygon")
          .map(option => (
            <label className={classes.SingleOptions} key={option.id}>
              <input
                type="checkbox"
                value={option.id}
                checked={selection == false ?selectedOptions.includes(option.id): false}
                onChange={() => handleCheckboxChange(option.id)}
              />
              {option.name}
            </label>
        ))}

        </div>

        
      </div>
      
      <div>
        <hr />
        <h2>LineString</h2>
        
        <div className={classes.listFeatures}>

        {totalFeatureList
          .filter(option => option.feature_type === "line")
          .map(option => (
            <label className={classes.SingleOptions} key={option.id}>
              <input
                type="checkbox"
                value={option.id}
                checked={selection == false ?selectedOptions.includes(option.id): false}
                onChange={() => handleCheckboxChange(option.id)}
              />
              {option.name}
            </label>
        ))}

        </div>

      </div>
      
      <div>
        <hr />
        <h2>Point</h2>
        <div className={classes.listFeatures}>
        {totalFeatureList
          .filter(option => option.feature_type === "point")
          .map(option => (
            <label className={classes.SingleOptions} key={option.id}>
              <input
                type="checkbox"
                value={option.id}
                checked={selection == false ?selectedOptions.includes(option.id): false}
                onChange={() => handleCheckboxChange(option.id)}
              />
              {option.name}
            </label>
        ))}
        </div>
      </div>
    </div>
  );
};

export default MultiCheckBoxCustom;
