import React from 'react'
import classes from "./ForgotPassword.module.css"
import OuterForm from '../../components/OuterForm/OuterForm'
import ForgetPasswordForm from '../../components/ForgetPasswordForm/ForgetPasswordForm'

function ForgotPassword() {
  return (
    <div className="outer-container">
            <OuterForm>
                <ForgetPasswordForm />

            </OuterForm>

        </div>
  )
}

export default ForgotPassword