import React, {useState} from 'react'
import { TextField, Button, Grid, Typography, Icon, Link } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Checkbox from '@mui/material/Checkbox';
import "./SignInForm.css"
import { useForm } from "react-hook-form";
import { apiQueries, apiTokenQueriesParam } from '../../utils/ApiCall';
import { SUCCESS_STATUS_CODE, FAILURE_STATUS_CODE } from '../../utils/constants';
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import CompanyLogo from "../../assets/MapNexa.png"

function SignInForm() {

  const navigate = useNavigate();

  const { register, handleSubmit,formState: { errors }, } = useForm();

    const  onSubmit = (event) =>{
      const data = {"email" : event.emailAddress, "password" : event.password}
      apiQueries("POST", "user/", data).then((res)=>{
        console.log(res)
        if(SUCCESS_STATUS_CODE.includes(res.status)){
          if (res.data?.success){
            window.localStorage.setItem('authToken', res.data.data.token)
            apiTokenQueriesParam("GET", "user/info", data, res.data.data.token).then((res)=>{
              if(SUCCESS_STATUS_CODE.includes(res.status)){
                if (res.data?.success){
                  window.localStorage.setItem('role', res.data.data.role)
                  if (res.data.data.organization === null){
                    navigate("/organization");

                  }
                  navigate("/home");  
                  
                }
              }
              else if(FAILURE_STATUS_CODE.includes(res.status)){
                toast.error(res.data.errorDesc)
              }     
            })

            navigate("/home");
            
          }
        }
        else if(FAILURE_STATUS_CODE.includes(res.status)){
          toast.error(res.data.errorDesc)
        }     
      })
       
    }

  return (
    <div className="login-section">
        <div className='login-header'>
        <img src={CompanyLogo} alt="CompanyLogo" className="CompanyLogo" />
        {/*<Typography className = "login-page-heading" variant="h5">Login into your account</Typography>*/}
        </div>

        {/* <div className='third-party-auth-container'>
        <ThirdPartyAuth btnLabel = "Login with Google" btnIcon = "/img/google.svg?url"/>
        <ThirdPartyAuth btnLabel = "Login with LinkedIn" btnIcon = "/img/linkedin.svg?url"/>
        </div> */}
    
    <div className="login-form">
    <form onSubmit={handleSubmit(onSubmit)}>
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <TextField
      className='form-input-container'
        variant="outlined"
        style={{ backgroundColor: '#FFFFFF' }}
        fullWidth
        {...register("emailAddress", {
          required : true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address"
          }})}
        InputProps={{
            startAdornment: (
              <Icon className='input__icon'>
                <EmailIcon  style={{ color: '#928989' }}/>
              </Icon>
            ),
          }}
          placeholder="Enter your email"
      />
      {errors.emailAddress?.type === "required" && (
        <p className ="error-msg" role="alert">Email is required</p>
      ) || errors.emailAddress?.type === "pattern" && (
        <p className ="error-msg" role="alert">{errors.emailAddress?.message}</p>
      )}
    </Grid>
    <Grid item xs={12}>
      <TextField
      className='form-input-container'
        variant="outlined"
        type="password"
        fullWidth
        style={{ backgroundColor: '#FFFFFF' }}
        {...register("password", {required : true})}
        InputProps={{
            startAdornment: (
              <Icon className='input__icon'>
                <LockIcon  style={{ color: '#928989' }}/>
              </Icon>
            ),
          }}
          placeholder="Enter password"
      />
      {errors.password?.type === "required" && (
        <p className ="error-msg" role="alert">Password is required</p>
      )}
    </Grid>
  </Grid>
<div className='login-misc-container'>
<div className='remain-login-container'>
      <Checkbox
      {...register('rememberMe', {
        required : false
      })} 
      sx={{
    color:'white',
  }}/>
      <p>Remember me</p>
    </div>

    <Link className='forget-password' href='forgot-password'>
    Forgot Password
    </Link>

</div>

  <Button type="submit" variant="contained" color="primary" fullWidth className='login-btn'>
    Login Here
  </Button>
</form>
    <div className='login-redirect-container'>
    <p>I don't have an account ! <a className= "signup-redirection" href="/register">Create an account</a></p>
    </div>
    </div>
        </div>
  )
}

export default SignInForm