import React, { useEffect, useState } from 'react';
import classes from './UploadOrderFolder.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { apiTokenQueries } from '../../utils/ApiCall';
import { SUCCESS_STATUS_CODE, FAILURE_STATUS_CODE } from "../../utils/constants"
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import toast from 'react-hot-toast';


function UploadOrderTable({ currentPage, setCurrentPage, itemsPerPage}) {
  const [orderRows, setOrderRows] = useState([]);
  const [totalPages, setTotalPages] = useState();

  const navigate = useNavigate();

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected+1);
  };

  useEffect(() => {
    const query_url = `p=${currentPage}&psz=10`
    const url = `ops/order?${query_url}`
    apiTokenQueries('GET', url).then((res) => {
      if (SUCCESS_STATUS_CODE.includes(res.status)) {
        const orderData = res.data.data;
        setTotalPages(res.data.total_pages)
        let tableData = [];
        orderData.map((data) => {
          tableData.push({
            'id' : data.id,
            'order_id': data.order_id,
            'address': data.address,
            'status': data.status,
            'created_at': data.created_at,
            'area': data.area,
          });
        });
        setOrderRows(tableData);
      } else if (FAILURE_STATUS_CODE.includes(res.status)) {
        toast.error(res.data.errorDesc);
      }
    });
  }, [currentPage]);

  return (
    <>
    <div className={classes.TableWholeContainer}>
      {orderRows ? (
        <div className={classes.MainContainer}>
          <div className={classes.TableOuterContainer}>
            <TableContainer className={classes.TableContainer} component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                  <TableCell className={classes.TableRow}>Order ID</TableCell>
                    <TableCell className={classes.TableRow}>Property Name</TableCell>
                    <TableCell className={classes.TableRow}>Created Date</TableCell>
                    <TableCell className={classes.TableRow}>Area</TableCell>
                    {/* <TableCell className={classes.TableRow}>Price&nbsp;(in USD)</TableCell> */}
                    {/* <TableCell className={classes.TableRow}>Takeoff Type</TableCell> */}
                    <TableCell className={classes.TableRow}>Status</TableCell>
                    {/* <TableCell  className={classes.TableRow}>Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.TableBody}>
                  {orderRows.map((row) => (
                    
                    <TableRow onClick={() => {
                        
                      navigate(`/upload/order/${row.order_id}/krypton`)
                    }}
                    className={classes.TableRowOrder}   key={row.order_id}>
                      <TableCell >{row.id}</TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                      >
                        {row.address}
                      </TableCell>
                      <TableCell >{row.created_at.split('T')[0]}</TableCell>
                      <TableCell>{row.area}</TableCell>
                      {/* <TableCell>RESEDENTIAL</TableCell> */}
                      <TableCell className={row.status==="draft" ? classes.DraftOrder : row.status==="in_progress" ? classes.OngoingOrder : classes.CompletedOrder}>
                      {row.status==="draft" ? 'Draft' : row.status==="in_progress" ? 'In Progress' : 'Completed'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      ) : null}

    
    </div>
    <ReactPaginate
    pageCount={totalPages}
    pageRangeDisplayed={1}
    marginPagesDisplayed={1}
    onPageChange={handlePageChange}
    containerClassName={classes.pagination}
    activeClassName={classes.active}
    previousLabel={'Previous'} // Custom label for "Previous" button
    nextLabel={'Next'} // Custom label for "Next" button
    previousClassName={(currentPage === 0 || currentPage === undefined) ? classes.disabled : ''}
    nextClassName={currentPage === totalPages - 1 ? classes.disabled : ''}
  />
  </>
  );
}

export default UploadOrderTable;
