import axios from 'axios';
import LayerGroup from 'ol/layer/Group';
import './PreviewOrderPage.css';

import { Modify, Select } from 'ol/interaction';
import AuthToken from '../../context/AuthToken';
import { altShiftKeysOnly } from 'ol/events/condition';
import { useLayerContext } from '../../context/LayerContext';
import { useLoader } from '../../context/LoaderProvider';
import { STATUS } from '../../utils/constants';
import { Fill, Stroke, Style } from 'ol/style';
import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import handleLayerTypeMap from '../../OpenLayers/OLControls/MapViewControl';
import handleLayerTypeSatellite from '../../OpenLayers/OLControls/SatelliteViewControl';
import handleZoomIn from '../../OpenLayers/OLControls/ZoomInControl';
import handleZoomOut from '../../OpenLayers/OLControls/ZoomOutControl';
import calculateArea from '../../OpenLayers/OLFeatures/CalculateArea';
import getNearmapImage from '../../OpenLayers/OLFeatures/GetNearmapImage';
import getUsersDetails from '../../OpenLayers/OLFeatures/GetUsersDetails';
import handleOrderVisiblity from '../../OpenLayers/OLFeatures/HandleOrderVisibility';
import initializeMap from '../../OpenLayers/OLFeatures/InitializeMap';
import initializeMarker from '../../OpenLayers/OLFeatures/InitializeMarker';
import latLongToCoordinates from '../../OpenLayers/OLFeatures/LatlatLongToCoord';
import removeAllInteraction from '../../OpenLayers/OLFeatures/RemoveInteraction';
import renderParcel from '../../OpenLayers/OLFeatures/RenderParcel';
import setNearmap from '../../OpenLayers/OLFeatures/SetNearmap';
import selectedStyle from '../../OpenLayers/OLStyles/EditStyle';
import ImageryDropdown from '../../components/ImageryDropdown/ImageryDropdown';
import { ORDER_URL, REMOVE_HOLES_URL } from '../../helper/ApiUrl';
import { apiTokenQueries, API_URL } from '../../utils/ApiCall';
import Toaster from '../../components/Toaster/Toaster';
import { SUCCESS_STATUS_CODE, FAILURE_STATUS_CODE } from '../../utils/constants';
import { TOAST_TYPE } from '../../utils/constants';
import DropDown from "../../components/DropDown/DropDown"
import CustomizeFeature from "../../components/CustomizeFeature/CustomizeFeature"
import MultiCheckbox from '../../components/MultiCheckBox/MultiCheckBox';
import SubmitOrder from '../../components/SubmitOrder/SubmitOrder';
import GeoJSONAccordion from '../../components/GeoJsonAccordian/Accordian';
import toast from 'react-hot-toast';
import { Button } from '@mui/material';
import getLayersData from "../../OpenLayers/OLFeatures/GetLayersData"
import getLayerData from "../../OpenLayers/OLFeatures/GetLayerData"
import renderOrderLayer from "../../OpenLayers/OLFeatures/RenderOrderLayer";
import getHdImage from '../../OpenLayers/OLFeatures/GetHDImage';
import setHDImage from "../../OpenLayers/OLFeatures/SetHDImageMap"


const PreviewOrderPage = ({ handleError, handleSuccess }) => {
  const token = AuthToken();
  const orderRef = useRef();
  const parcelRef = useRef();
  const markerRef = useRef();
  const { id } = useParams();
  const mapElement = useRef();
  const contentRef = useRef();
  const tileGridLyr = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [map, setMap] = useState();
  const nearmapImageRef = useRef();

  const completedGridLyr = useRef();
  const search = useLocation().search;
  const [status, setStatus] = useState(null);
  const [popup, showPopup] = useState(false);
  const [address, setAddress] = useState(null);
  const [imageDate, setImageDate] = useState();
  const [center, setCenter] = useState([0, 0]);
  const [hoverElem, setHoverElem] = useState();
  // const { startLoader, stopLoader } = useLoader();
  const [hoverMapKey, setHoverMapKey] = useState();
  const [zoomLayer, setZoomLayer] = useState(true);
  const [coordinates, setCoordinates] = useState([]);
  const [polygonList, setPolygonList] = useState([]);
  const [parcelEdit, setParcelEdit] = useState(false);
  const [orderActive, setOrderActive] = useState(true);
  const [usersDetails, setUsersDetails] = useState([]);
  const [hoverParcelId, setHoverParcelId] = useState();
  const [uploadedLayer, setUploadedLayer] = useState([]);
  const [showDone, setShowDone] = useState(false);
  const [nearmapcoords, setNearmapcoords] = useState([]);
  const [featureGroup, setFeatureGroup] = useState([]); //Dropdown for feature group
  const [featureList, setFeatureList] = useState([]);
  const [featureSelected, setFeatureSelected] = useState(); //selected feature shows up here
  const [featureCount, setFeatureCount] = useState(0.0)
  const [totalFeatureList, setTotalFeatureList] = useState([]); //Used to show the total lists in popup

  const [nearmapImageDate, setNearmapImageDate] = useState('');
  const [nearmapSurveyData, setNearmapSurveyData] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageBound, setImageBound] = useState(null);
  const [showNearmapImage, toggleNearmapImage] = useState(false);
  const [hoverParcelElement, setHoverParcelElement] = useState();
  const [showExportOptions, setShowExportOptions] = useState(false);

  const [hoverOrderFeatureId, setHoverOrderFeatureId] = useState();

  const [parcelArea, setParcelArea] = useState(0.0)
  const [selectedHoverFeature, setSelectedHoverFeature] = useState();

  const [hoverOrderFeatureElement, setHoverOrderFeatureElement] = useState();
  const [mappedFeatureList, setMappedFeatureList] = useState([]);
  const [viewId, setViewId] = useState(null);
  const [layersData, setLayersData] = useState([]);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [layersCount, setLayersCount] = useState();


  const {
    setOlMap,
    editInteraction,
    multipleSelectInteraction,

  } = useLayerContext();

  parcelRef.current = new LayerGroup({
    title: 'Parcel',
    layers: [],
  });

  orderRef.current = new LayerGroup({
    title: 'Order',
    layers: [],
  });

  markerRef.current = new LayerGroup({
    title: 'Marker',
    layers: [],
  });

  nearmapImageRef.current = new LayerGroup({
    title: 'NearmapImage',
    layers: [],
  });


  tileGridLyr.current = new LayerGroup({
    title: 'Tile Imagery',
    layers: [],
  });

  completedGridLyr.current = new LayerGroup({
    title: 'Completed Grid Layer',
    layers: [],
  });


  const [hoverData, setHoverData] = useState({
    id: null,
    name: null,
    measurement: null,
    measurement_type: null,
  });

  const [parcelStyle, setParcelStyle] = useState([
    new Style({
      stroke: new Stroke({
        color: 'red',
        width: 2,
      }),
      fill: new Fill({
        color: 'rgb(255, 255, 255,0.2)',
      }),
    }),
  ]);

  // useLayoutEffect(() => {
  //   startLoader();
  // }, []);

  const select = new Select({
    wrapX: false,
    style: selectedStyle,
    condition: altShiftKeysOnly,
  });

  const modify = new Modify({
    features: select.getFeatures(),
  });

  const selectToDelete = new Select({
    wrapX: false,
    style: selectedStyle,
  });

  const addEditInteraction = () => {
    document.getElementsByClassName('edit-parcel-buttons')[0].click();
  };
  const addDeleteInteraction = () => {
    document.getElementsByClassName('delete-parcel-buttons')[0].click();
  };

  const deletePolygon = () =>{
    document.body.classList.remove('cut-cursor');
    document.body.classList.add("delete-cursor");
    setParcelStyle([
      new Style({
        stroke: new Stroke({
          color: 'red',
          width: 2,
        }),
        fill: new Fill({
          color: 'rgb(255, 255, 255,0.2)',
        }),
      }),
    ])
    setShowDone(true)
    setZoomLayer(false);
    removeAllInteraction(map);
    map.addInteraction(selectToDelete);
    // map.addInteraction(modify);

    selectToDelete.on('select', (event) => {
      if (event.selected.length > 0) {
        // At least one feature is selected
        const selectedFeature = event.selected[0]; // Assuming you want to delete the first selected feature
        const updatedList = polygonList.filter(item => item.id !== selectedFeature.id_);
        setPolygonList(updatedList);
        removeAllInteraction(map);
        addDeleteInteraction();
            }
          
          });
    

  }

  // useEffect(() => {
  //   if (!nearmapImageDate) {
  //     if (status === STATUS.DRAFT || status === STATUS.DIY) {
  //       if (nearmapcoords.length && nearmapcoords[0] != Infinity) {
  //         getNearmapImage(id, token, nearmapcoords, setNearmapSurveyData);
  //       }
  //     }
  //   }
  // }, [nearmapcoords]);

  const getParcelData = () => {

    apiTokenQueries("GET", `order/prop-id/${id}`).then((res)=>{
      if(SUCCESS_STATUS_CODE.includes(res.status)){
          const orderData = res.data.data
          console.log(orderData)
          setAddress(orderData.address)
          setStatus(orderData.status)
          setImageDate(orderData.imagery_date)
          setNearmapImageDate(orderData.imagery_date);
          setCenter([orderData.long, orderData.lat])
          if ("geometry" in orderData.parcel_data ){
            setCoordinates(orderData.parcel_data.geometry.coordinates, setPolygonList);
          }          
          // stopLoader()
      }
      else if(FAILURE_STATUS_CODE.includes(res.status)){
        handleSuccess(TOAST_TYPE.ERROR, res.data.errorDesc);
      }     
  })

  };


  useEffect(() => {
    initializeMap(mapElement, setMap);
    getParcelData();
  }, []);

  useEffect(() => {
    if (map) {
      renderParcel(
        polygonList,
        map,
        parcelRef,
        parcelStyle,
        zoomLayer,
        status,
        nearmapcoords,
        setNearmapcoords
      );
    }
  }, [polygonList, parcelStyle]);

  useEffect(() => {
    const elem = document.getElementById('popup-cont');
    setHoverElem(elem);
    initializeMarker(
      map,
      center,
      hex2rgba,
      setHoverData,
      showPopup,
      markerRef,
      setHoverMapKey,
      setSelectedHoverFeature,
      elem
    );
  }, [center]);

  useEffect(() => {
    latLongToCoordinates(coordinates, setPolygonList);
  }, [coordinates]);

  useEffect(() => {
    if (map)
      map.getLayers().forEach(function (layer) {
        if (layer && layer.get('title') == 'NearmapImage') {
          if (showNearmapImage) layer.setVisible(true);
          else layer.setVisible(false);
        }
      });
  }, [showNearmapImage]);


  useEffect(() => {
    if (
      (map && status === STATUS.MEASUREMENT_COMPLETED) ||
      status === STATUS.IN_PROGRESS
    )
      getHdImage(id,
        setImageUrl,
        setImageBound,)
  }, [map, status]);

  useEffect(() => {
    if (map && imageUrl && imageBound)
      setHDImage(
        map,
        imageUrl,
        imageBound,
        nearmapImageRef,
      );
  }, [imageUrl, imageBound]);

 


  const hex2rgba = (hex, alpha) => {
    if (hex) {
      const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
      return `rgba(${r},${g},${b},${alpha})`;
    }
  };

  useEffect(() => {
    handleOrderVisiblity(map, uploadedLayer, orderActive, setUploadedLayer);
  }, [orderActive]);



  useEffect(() => {
    if (!nearmapImageDate) {
      if (status === STATUS.DRAFT) {
        if (nearmapcoords.length && nearmapcoords[0] != Infinity) {
          getNearmapImage(id, nearmapcoords, setNearmapSurveyData);
        }
      }
    }
  }, [nearmapcoords]);

  useEffect(() => {
    let totalArea = 0
    for (let i = 0; i < polygonList.length; i++) {
     totalArea+=parseFloat(polygonList[i].measurement)
    }
    // totalArea = totalArea * 43560
    setParcelArea(totalArea.toFixed(2))

    
  }, [polygonList]);


  useEffect(() => {

    // if (status === "measurement_completed")
    apiTokenQueries("GET", `order/prop-id/${id}/view`).then((res)=>{
      if(SUCCESS_STATUS_CODE.includes(res.status)){
          const viewData = res.data.data
          if (viewData.length){
            const viewId = viewData[0].id
            setViewId(viewId)
            setStatus("measurement_completed")
          //   apiTokenQueries("GET", `order/prop-id/${id}/view/${viewId}/layer/info`).then((res)=>{
          //     if(SUCCESS_STATUS_CODE.includes(res.status)){
          //         const layersData = res.data.data
          //         setMappedFeatureList(layersData)

          //     }
          //     else if(FAILURE_STATUS_CODE.includes(res.status)){
          //     toast.error(res.data.errorDesc)
          //     }     
          // })

          }
      }
      else if(FAILURE_STATUS_CODE.includes(res.status)){
      toast.error(res.data.errorDesc)
      }     
  })
    
  }, [status]);

  useEffect(() => {
    if (viewId !== null)
    {
      getLayersData(
        id,
        viewId,
        setLayersData,
        setUploadedLayer
      );
    }
  }, [viewId]);

  useEffect(() => {
    setLayersCount(layersData.length);
    layersData.forEach((layer) => {
      const layerId = layer.id;
      getLayerData(
        id,
        viewId,
        layerId,
        null,
        setDownloadData,
        setUploadedLayer
      );
    });
  }, [layersData]);

  useEffect(() => {
    if (map) {
      setOlMap(map);
      if (status === STATUS.DIY || status === STATUS.MEASUREMENT_COMPLETED) {
        renderOrderLayer(map, hex2rgba, orderRef, uploadedLayer);
      }
      // stopLoader();
    }
  }, [uploadedLayer, layersData]);


  // useEffect(() => {
  //   if (nearmapImageDate === 'SATELLITE') {
  //     map.getLayers().forEach((layer) => {
  //       if (layer && layer.get('title') === 'Tile Imagery') {
  //         map.removeLayer(layer);
  //       }
  //     });
  //   } else if (status === STATUS.DRAFT) {
  //     if (nearmapcoords.length)
  //       nearmapcoords.map((item) => {
  //         setNearmap(map, nearmapImageDate, item, tileGridLyr);
  //       });
  //   }
  // }, [nearmapcoords, nearmapImageDate]);



  return (
    <div ref={contentRef} className="ol-order-id-page-share">

  
      <div className='map-edit-container'>
      <div className='map-left-bar-share'>
            <div className='ConfirmPropertyContainer'>
              <div className='AddressHeader'>
                {/* <span className='TakeOffHeader'>TakeOff Details</span> */}
                <span>{address} </span>
              </div>
                
              {/* <div className='LotArea'>
              <span>
              <img src={LotIcon} alt="Eye Icon" /> Lot Area
                </span>
                <span>
                  {(parcelArea * 43560).toFixed(2)} sqft
                </span>

              </div> */}

              <div className='PropertyFeature'>

                <div className='PropertFeatureList'>
                  <span className='PropertFeatureHeader'>Property features</span>
                  <div className='PropertyFeatureList'>
                    {status === "draft" ? 
                    <div>
                    <DropDown featureGroup = {featureGroup} setFeatureList={setFeatureList} setFeatureSelected={setFeatureSelected}/>
                    <CustomizeFeature options = {featureList} setFeatureCount = {setFeatureCount} totalFeatureList={totalFeatureList} setFeatureList={setFeatureList}/>
                    </div>: 
                    null}
                    {status !== "measurement_completed" ?<MultiCheckbox options = {featureList} setFeatureCount = {setFeatureCount}/> : null }
                    
                  </div>
                </div>
              </div>
              
                       
            </div>
            {status !== "measurement_completed" ?  <SubmitOrder parcelArea={parcelArea} featureList={featureList} featureSelected={featureSelected} polygonList={polygonList} id= {id} orderStatus = {status} setOrderStatus = {setStatus} nearmapImageDate={nearmapImageDate}/>  : 
            <GeoJSONAccordion order_id={id} geojsonData= {uploadedLayer} setUploadedLayer = {setUploadedLayer} uploadedLayer={uploadedLayer} addFeedback={'submitOrder'}  map={map} hex2rgba = {hex2rgba}/>}
            {/* null} */}
             <div id="hover-popup" className='HoverPop'></div>
           
          </div>

      <div ref={mapElement} id="map-cont" className="main-ol-container">
        {status === STATUS.DRAFT && nearmapSurveyData && polygonList.length ? (
          <div className="image-date-cont">
            <ImageryDropdown
              nearmapSurveyData={nearmapSurveyData}
              setNearmapImageDate={setNearmapImageDate}
            />
          </div>
        ) : null}
        <div id="popup-cont" className="popup"></div>
        <div className="map-buttons">
          <div className="map-type-buttons">
            <button onClick={() => handleLayerTypeMap(map)}>Map</button>
            <button onClick={() => handleLayerTypeSatellite(map)}>
              Satellite
            </button>
          </div>
          <div className="map-zoom-buttons">
            <button onClick={() => handleZoomIn(map)}>+</button>
            <button onClick={() => handleZoomOut(map)}>-</button>
          </div>
        </div>
      </div>

      </div>
      
    </div>
  );
};

export default PreviewOrderPage;
