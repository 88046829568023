import React, {useState, useEffect} from 'react'
import classes from "./VerifyOtp.module.css"
import OtpLogo from "../../assets/otp_lock.svg"
// import { MuiOtpInput } from 'mui-one-time-password-input'
import { Button } from '@mui/material'
import { apiQueries } from '../../utils/ApiCall'
import toast from 'react-hot-toast'
import { SUCCESS_STATUS_CODE, FAILURE_STATUS_CODE } from '../../utils/constants'
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';




function VerifyOtp({setOtpEntered}) {
  console.log("hello")

    const [otp, setOtp] = useState('')
    const [email, setEmail] = useState(null)
    const navigate = useNavigate();
    

    useEffect(() => {
        const searchParams = new URLSearchParams(document.location.search)
        const user_email = searchParams.get("user_mail")
        if( user_email === null)
            navigate('/register')
        else{
            setEmail(user_email)
        }
      }, []);

      const signUpRoute = () =>{
        navigate('/register')
      }

    const handleChange = (newValue) => {
        setOtp(newValue)
      }

    const resendOtp = () =>{
        const data = {"email" : email}
      apiQueries("POST", "user/otp/resend", data).then((res)=>{
        if(SUCCESS_STATUS_CODE.includes(res.status)){
            toast.success("OTP Sent on email")
            
        }
        else if(FAILURE_STATUS_CODE.includes(res.status)){
          toast.error(res.data.errorDesc)
        }     
      })

    }

    const submitOtp = () =>{
        if (otp.length !==6){
            toast.error("OTP should be of six digit")
            return
        }
        const data = {"email" :email, "otp" : otp}
      apiQueries("POST", "user/otp/verify", data).then((res)=>{
        if(SUCCESS_STATUS_CODE.includes(res.status)){
          setOtpEntered(true)
        }
        else if(FAILURE_STATUS_CODE.includes(res.status)){
          toast.error(res.data.errorDesc)
        }     
      })

    }

  return (
    <div className={classes.OtpContainer}>
        <img src={OtpLogo} alt="Otp Lock" />
        <div className={classes.OTPVerificationText}>
            OTP verfication
        </div>
        <div className={classes.OtpMessage}>
        {`Please enter the One-Time-Password (OTP) to verify your account. A One-Time-Password is sent to ${email}`}
        </div>
        {/* <MuiOtpInput className={classes.OtpBox} value={otp} onChange={handleChange} length={6}/> */}
        <OtpInput
          value={otp}
          className ={classes.OtpInput}
          onChange={handleChange}
          numInputs={6}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
        />
        <div className={classes.ResendCode} onClick={resendOtp}>
            Resend Code
        </div>
            <Button className={classes.SubmitOtp} variant="contained" onClick={submitOtp}>Submit OTP</Button>
            <div className={classes.BackToSignUp}>Want to change credentials? <span className={classes.SignUpBtn} onClick={signUpRoute}>Back to Sign Up</span></div>
    </div>

  )
}

export default VerifyOtp