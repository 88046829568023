import React, { useState } from 'react';
import classes from "./Organization.module.css"
import OuterForm from '../../components/OuterForm/OuterForm';
import { TextField, Button, Grid, Typography, Icon, Link } from '@mui/material';
import { apiTokenQueries } from '../../utils/ApiCall';
import { SUCCESS_STATUS_CODE, FAILURE_STATUS_CODE } from '../../utils/constants';
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';

function Organization() {
  const [orgCreation, setOrgCreation] = useState(true);
  const [orgName, setOrgName] = useState('');
  const [orgCode, setOrgCode] = useState(null);

  const navigate = useNavigate();
  
  const CreateOrgSubmit = (e) =>{
    e.preventDefault()
    const data = {"org_name" : orgName}
    apiTokenQueries("POST", "org/", data).then((res)=>{
        console.log(res)
        if(SUCCESS_STATUS_CODE.includes(res.status)){
          if (res.data?.success){
            navigate("/home");
            
          }
        }
        else if(FAILURE_STATUS_CODE.includes(res.status)){
          toast.error(res.data.errorDesc)
        }     
      })
  }

  const JoinOrgSubmit = (e) =>{
    e.preventDefault()
    const data = {"org_code" : orgCode}
    apiTokenQueries("POST", "org/join", data).then((res)=>{
        console.log(res)
        if(SUCCESS_STATUS_CODE.includes(res.status)){
          if (res.data?.success){
            navigate("/home");
            
          }
        }
        else if(FAILURE_STATUS_CODE.includes(res.status)){
          toast.error(res.data.errorDesc)
        }     
      })
  }
  return (
    <div className="outer-container">
            <OuterForm>
                {orgCreation ? (<div className={classes.CreateOrgContainer}>
                    <Typography className = "login-page-heading" variant="h5">Create your organization</Typography>
                    <form onSubmit={CreateOrgSubmit} className={classes.CreateOrgForm}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                            <TextField
                            className='form-input-container'
                                variant="outlined"
                                value={orgName}
                                style={{ backgroundColor: '#FFFFFF' }}
                                fullWidth
                                placeholder="Enter yout Organization Name"
                                onChange={(e)=>{
                                    console.log(e.target.value)
                                    setOrgName(e.target.value)
                                }}
                            />
                            </Grid>
                        </Grid>

                        <Button type="submit" variant="contained" color="primary">
                            Create Organization
                        </Button>
                    </form> 

                    <div className={classes.ReferalCodeContainer}>Have a referal code? <span onClick={()=>{
                        setOrgCreation(false)
                    }} className={classes.JoinOrgBtn}>Join organization here</span></div>
                    
                    
                    </div>) : (<div className={classes.JoinOrgContainer}>
                    <Typography className = "login-page-heading" variant="h5">Join Organization</Typography>
                    <form onSubmit={JoinOrgSubmit} className={classes.CreateOrgForm}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                            <TextField  
                            className='form-input-container'
                                variant="outlined"
                                value={orgCode}
                                style={{ backgroundColor: '#FFFFFF' }}
                                fullWidth
                                placeholder="Organization Code here"
                                onChange={(e)=>{
                                    setOrgCode(e.target.value)
                                }}
                            />
                            </Grid>
                        </Grid>

                        <Button type="submit" variant="contained" color="primary">
                            Join Organization
                        </Button>
                    </form> 

                    <div onClick={()=>{
                        setOrgCreation(true)
                    }} className={classes.CreateOrgLink}>Create an Organization</div>
                    
                    
                    </div>)}
            </OuterForm>

        </div>
  )
}

export default Organization;
