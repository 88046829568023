import React, { useState, useEffect, useRef } from 'react';
import classes from "./ShareHeader.module.css";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import { apiTokenQueries } from '../../utils/ApiCall';
import { SUCCESS_STATUS_CODE, FAILURE_STATUS_CODE } from '../../utils/constants';
import toast from 'react-hot-toast';
import CompanyLogo from "../../assets/MapNexa.png";
import Menu from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


function ShareHeader() {
    const ref = useRef();
    const navigate = useNavigate();
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [propertyDropdownOpen, setPropertyDropdownOpen] = useState(false);
    const [settingsDropdownOpen, setSettingsDropdown] = useState(false);
    const [userIntitials, setUserIntitials] = useState(null);
    const [userName, setUserName] = useState(null);
    const [toggle, setToggle] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
   

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };
    const navigateDashboard = () => {
        navigate("/home");
    }

    useEffect(() => {
        const checkIfClickedOutside = e => {
          // If the menu is open and the clicked target is not within the menu,
          // then close the menu
          if (toggle && ref.current && !ref.current.contains(e.target)) {
            setToggle(false)
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
     
    }
  }, [toggle])
  
  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showTooltip && ref.current && !ref.current.contains(e.target)) {
        setShowTooltip(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    
return () => {
  // Cleanup the event listener
  document.removeEventListener("mousedown", checkIfClickedOutside)
 
}
}, [showTooltip])
  

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    }

    const togglePropertiesDropdown = (delay = 300) => {
        setPropertyDropdownOpen(!propertyDropdownOpen);
        if (!propertyDropdownOpen) {
            // If dropdown is opened, do nothing
            return;
        }
        // Add a delay before closing the dropdown
        setTimeout(() => {
            setPropertyDropdownOpen(false);
        }, delay);
    }
    const toggleSettingsDropdown = () => {
        setSettingsDropdown(!settingsDropdownOpen);
    }

    const handleEditProfile = () => {
        navigate("/profile-setting");
    }

    const handleLogout = () => {
        window.localStorage.removeItem('authToken');
        navigate("/login");
    }
    

    return (
        <div className={classes.HeaderDiv}>
            <div className={classes.LeftContainerShare}>
                <div onClick={navigateDashboard} className={classes.CompanyHeading}>
                    <img src={CompanyLogo} alt="CompanyLogo" className={classes.CompanyLogo} />
                </div>
                
            </div>
            <div className={classes.RightContainerShare}>
            <a className={classes.RegisterBtnShare} href='https://app.mapnexa.com/register' target='_blank'>Register Here</a>
              
            </div>
           
        </div>
    )
}

export default ShareHeader;
