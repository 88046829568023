import { Fill, Style } from "ol/style";
import { get as getProjection } from "ol/proj";

const hoverEffect = (map, hex2rgba, setHoverMapKey, elem, original=false) => {
  var selectedFeature = null;
  let key = map.on("pointermove", function (e) {
    setHoverMapKey(key);
    map.getViewport().style.cursor = "";

    // Convert pixel coordinates to map coordinates
    var coordinate = map.getCoordinateFromPixel(e.pixel);

    var feature = map.forEachFeatureAtPixel(e.pixel, function (f) {
      return f;
    });

    if (feature && feature.get("title"))
      map.getViewport().style.cursor = "pointer";

    if (feature && feature.get("color")) {
      const elem1 = document.createElement("div");
      const elem2 = document.createElement("div");
      const elem3 = document.createElement("div");

      elem1.innerText = `${feature.get("name")} / ID${feature.get("hover_id")}`;
      // elem2.innerText = `Name: ${feature.get("name")}`;
      elem3.innerText = `${feature.get(
        "measurement"
      )} ${feature.get("measurement_type")}`;
      elem1.classList.add("hover-element");
      // elem2.classList.add("hover-element");
      elem3.classList.add("hover-element");
      elem.innerHTML = "";
      elem.appendChild(elem1);
      // elem.appendChild(elem2);
      elem.appendChild(elem3);

      // Set position based on map coordinates
      const [x, y] = map.getPixelFromCoordinate(coordinate);
      const currentZoom = map.getView().getZoom(); // OpenLayers
      let currentWidth = window.innerWidth;
      let left_factor = currentWidth/7.8
      if (currentWidth > 1400){
        left_factor = left_factor + 150
      }


      // document.documentElement.clientHeight * 0.65;
      
      let top_factor = currentWidth/50.2
      // let top_factor = 0
      if (original){
        top_factor =  top_factor +( 4 * 6.65);
      }
      top_factor =  top_factor +( 1.8 * 6.65);
      // elem.style.display = "block";
      // elem.style.left = x + left_factor + "px";
      // elem.style.top = y + top_factor + "px";

      elem.style.display = "block";
      elem.style.left = x +"px"
      elem.style.top = y  + top_factor + "px"

      let color = hex2rgba(feature.get("color"), 0.8);
      var selectStyle = new Style({
        fill: new Fill({
          color: color,
        }),
      });

      if (selectedFeature) selectedFeature.setStyle(undefined);
      selectedFeature = feature;
      feature.setStyle(selectStyle);
    } else {
      if (selectedFeature) selectedFeature.setStyle(undefined);
      elem.style.display = "none";
    }
  });
};

export default hoverEffect;
