import axios from 'axios';
import { ORDER_URL } from '../../helper/ApiUrl';

const getHdImage = (
  id,
  setImageUrl,
  setImageBound,
) => {
  axios
    .get(`${ORDER_URL}${id}/image`, {
      headers: {
        // Authorization: `Token ${token}`,
        Accept: 'application/json',
      },
    })
    .then((response) => {
      const data = response.data.data;
      if (data.length) {
        data.map((item) => {
          setImageUrl(item.url);
          setImageBound([
            item.min_long,
            item.min_lat,
            item.max_long,
            item.max_lat,
          ]);
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getHdImage;
