export const LOGIN_URL = `${process.env.REACT_APP_ROUTE}/v1/api/user/`;
export const SIGNUP_URL = `${process.env.REACT_APP_ROUTE}/v1/api/user/signup`;
export const OTP_VERIFY_URL = `${process.env.REACT_APP_ROUTE}/v1/api/user/otp/verify`;
export const RESEND_OTP_URL = `${process.env.REACT_APP_ROUTE}/v1/api/user/otp/resend`;
export const FETCH_FEATURE_URL = `${process.env.REACT_APP_ROUTE}/v1/api/property/feature`;
export const CREATE_PROPERTY_URL = `${process.env.REACT_APP_ROUTE}/v1/api/property/`;
export const USER_INFO_URL = `${process.env.REACT_APP_ROUTE}/v1/api/user/info`;
export const USER_ORG_CREATE_URL = `${process.env.REACT_APP_ROUTE}/v1/api/org/`;
export const USER_ORG_JOIN_URL = `${process.env.REACT_APP_ROUTE}/v1/api/org/join`;
export const RESET_PASSWORD_URL = `${process.env.REACT_APP_ROUTE}/v1/api/user/reset-password`;
export const FORGOT_PASSWORD_URL = `${process.env.REACT_APP_ROUTE}/v1/api/user/forgot-password`;
export const ORDER_LIST_URL = `${process.env.REACT_APP_ROUTE}/v1/api/property/user`;
export const ORDER_URL = `${process.env.REACT_APP_ROUTE}/v1/api/order/prop-id/`;
export const ORDER_SHARE_URL = `${process.env.REACT_APP_ROUTE}/v1/api/property/share/`;
export const GET_LAYERS_URL = `${process.env.REACT_APP_ROUTE}/v1/api/operation/order`;
export const LAYERS_DATA_URL = `${process.env.REACT_APP_ROUTE}/v1/api/property/prop-id`;
export const UPLOAD_LAYERS_DATA_URL = `${process.env.REACT_APP_ROUTE}/v1/api/operation/order`;
export const REFRESH_TOKEN_URL = `${process.env.REACT_APP_ROUTE}/v1/api/token/refresh/`;
export const TRANSACTION_HISTORY_URL = `${process.env.REACT_APP_ROUTE}/v1/api/org/transaction`;
export const CREDIT_STATUS_URL = `${process.env.REACT_APP_ROUTE}/v1/api/org/credits`;
export const DOWNLOAD_LAYERS_URL = `${process.env.REACT_APP_ROUTE}/v1/api/property/prop-id`;
export const ADD_FEEDBACK = `${process.env.REACT_APP_ROUTE}/v1/api/property/prop-id/`;
export const ALL_PROPERTIES_URL = `${process.env.REACT_APP_ROUTE}/v1/api/property/user?p=1&psz=4`;
export const NEARMAP_VERIFY_URL = `${process.env.REACT_APP_N_ROUTE}/coverage/v2/point/`;
export const ORDER_SHARE_DATA_URL = `${process.env.REACT_APP_ROUTE}/v1/api/property/share/`;
export const ADD_TAG_URL = `${process.env.REACT_APP_ROUTE}/v1/api/property`;
export const UPDATE_TAGS_URL = `${process.env.REACT_APP_ROUTE}/v1/api/property/prop-id`;
export const TEAM_DETAILS_URL = `${process.env.REACT_APP_ROUTE}/v1/api/org/user`;
export const FEEDBACK_ORDER_LIST = `${process.env.REACT_APP_ROUTE}/v1/api/operation/feedback-order`;
export const SUBMIT_ORDER = `${process.env.REACT_APP_ROUTE}/v1/api/operation/order/`;
export const ADD_VIEW_USER = `${process.env.REACT_APP_ROUTE}/v1/api/operation/user`;
export const CREDIT_REQUEST_URL = `${process.env.REACT_APP_ROUTE}/v1/api/org/credit-request`;
export const VIEW_CLONE_URL = `${process.env.REACT_APP_ROUTE}/v1/api/property/prop-id/`;
export const DELETE_VIEW_URL = `${process.env.REACT_APP_ROUTE}/v1/api/property/prop-id/`;
export const ADD_NEW_LAYER_URL = `${process.env.REACT_APP_ROUTE}/v1/api/property/prop-id/`;
export const REMOVE_HOLES_URL = `${process.env.REACT_APP_ROUTE}/v1/api/metron/parcel-hole`;
export const DIY_PARCEL_URL = `${process.env.REACT_APP_ROUTE}/v1/api/property/diy`;
export const DIY_NEARMAP_DATE = `${process.env.REACT_APP_ROUTE}/v1/api/property/coverage`;
export const ORG_TRANSACTION = `${process.env.REACT_APP_ROUTE}/v1/api/organization/transaction`;
export const RAISE_FEEDBACK = `${process.env.REACT_APP_ROUTE}/v1/api/organization/feedback`;
export const INVITE_MEMBER = `${process.env.REACT_APP_ROUTE}/v1/api/organization/invitation`;
export const NEARMAP_IMAGE_API = `http://us0.nearmap.com/staticmap?&httpauth=false&`;
export const FILE_UPLOAD = `${process.env.REACT_APP_ROUTE}/v1/api/organization/file`;
