import classes from "./FilterSearchContainer.module.css"
import {
  Combobox,
  ComboboxInput,
} from "@reach/combobox";
import React, { useEffect, useState } from 'react';

import { TextField, Button, Icon } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export default function FilterSearchBox({addressSearch, setAddressSearch}) {

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if( e.target.value.length === 0){
      setAddressSearch('');
    }
  };

  const handleInputSubmit = () => {
    // You can perform some action here with the inputValue
    setAddressSearch(inputValue);
    console.log("submitted")
  };


  return (
    <div className={classes.OuterDiv}>
      <div className={classes.Search}>

      <TextField
      className={classes.Searchbox}
        variant="outlined"
        style={{ backgroundColor: '#FFFFFF' }}
        InputProps={{
            startAdornment: (
              <Icon className='input__icon'>
                <SearchIcon  style={{ color: '#928989'}}/>
              </Icon>
            ),
          }}
          placeholder="Search By Address"
          value={inputValue}
          onChange={handleInputChange} // Add onChange handler
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleInputSubmit();
            }
          }}
      />
      </div>
      <div className={classes.SearchIconContainer} onClick={handleInputSubmit}>
        <SearchIcon/>

      </div>
    </div>
    
  );
}
