import React, { useState, useEffect, useCallback, useMemo } from "react";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Fill, Style } from "ol/style";
import { Select } from "ol/interaction";
import { pointerMove } from "ol/events/condition";
import classes from "./Accordian.module.css";
import toast from "react-hot-toast";
import {
  SUCCESS_STATUS_CODE,
  FAILURE_STATUS_CODE,
} from "../../utils/constants";
import { apiTokenQueries } from "../../utils/ApiCall";
import FeedbackForm from "../Feedback/Feedback";

const GeoJSONAccordion = ({
  order_id,
  geojsonData,
  setUploadedLayer,
  uploadedLayer,
  map,
  hex2rgba,
  addFeedback = true,
}) => {
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const [hideAllLayers, setHideAllLayers] = useState(false);
  const [hoveredFeatureId, setHoveredFeatureId] = useState(null);
  const [isFeedbackPopupOpen, setFeedbackPopupOpen] = useState(false);

  const groupedFeatures = useMemo(() => {
    return uploadedLayer.reduce((acc, feature) => {
      if (!acc[feature.type]) {
        acc[feature.type] = [];
      }
      acc[feature.type].push(feature);
      return acc;
    }, {});
  }, [uploadedLayer]);

  const sortedGroupedFeatures = useMemo(() => {
    const sorted = {};
    ["polygon", "line", "point"].forEach(type => {
      if (groupedFeatures[type]) {
        sorted[type] = [...groupedFeatures[type]].sort((a, b) => {
          if (type === "point") {
            // Sort points by their count (number of data points)
            return b.data.length - a.data.length;
          } else {
            // Sort polygons and lines by total measurement
            const totalA = a.data.reduce((sum, measurement) => sum + parseFloat(measurement.measurement), 0);
            const totalB = b.data.reduce((sum, measurement) => sum + parseFloat(measurement.measurement), 0);
            return totalB - totalA; // Sort in descending order
          }
        });
      }
    });
    return sorted;
  }, [groupedFeatures]);

  const handleMapHover = useCallback((feature) => {
    setHoveredFeatureId(feature ? feature.get("id") : null);
  }, []);

  const toggleAccordion = useCallback((geojsonId) => {
    setOpenAccordionId((prevId) => (prevId === geojsonId ? null : geojsonId));
  }, []);

  const handleGeojsonVisibilityChange = useCallback((clickedGeojson) => {
    setUploadedLayer((prevData) =>
      prevData.map((geojson) =>
        geojson.id === clickedGeojson.id
          ? { ...geojson, visible: !geojson.visible }
          : geojson
      )
    );
  }, [setUploadedLayer]);

  const handleAllLayerVisibility = useCallback(() => {
    const allLayersVisible = hideAllLayers;
    setHideAllLayers(!hideAllLayers);
    setUploadedLayer((prevData) =>
      prevData.map((geojson) => ({
        ...geojson,
        visible: !allLayersVisible,
      }))
    );
  }, [hideAllLayers, setUploadedLayer]);

  const handleColorChange = useCallback((clickedGeojson, color) => {
    setUploadedLayer((prevData) =>
      prevData.map((geojson) =>
        geojson.id === clickedGeojson.id
          ? { ...geojson, color: color, fillColor: color, strokeColor: color }
          : geojson
      )
    );
  }, [setUploadedLayer]);

  const handleMouseEnter = useCallback((measurement, geojson) => {
    if (map) {
      map.getLayers().forEach((layer) => {
        if (layer.get("title") === "Order") {
          layer.getLayers().forEach((item) => {
            if (item.get("title") === geojson.name) {
              const source = item.getSource();
              source.getFeatures().forEach((feature) => {
                if (
                  feature.get("id") === measurement.id &&
                  feature.get("name") === geojson.name
                ) {
                  const color = hex2rgba(feature.get("color"), 1);
                  const newStyle = new Style({ fill: new Fill({ color }) });
                  feature.setStyle(newStyle);
                  setHoveredFeature(feature);
                  setHoveredFeatureId(measurement.id);
                }
              });
            }
          });
        }
      });
    }
  }, [map, hex2rgba]);

  const handleMouseLeave = useCallback(() => {
    if (hoveredFeature) {
      hoveredFeature.setStyle(undefined);
    }
    setHoveredFeatureId(null);
  }, [hoveredFeature]);

  const confirmOrder = useCallback(() => {
    apiTokenQueries("POST", `ops/order/${order_id}/confirm`).then((res) => {
      if (SUCCESS_STATUS_CODE.includes(res.status)) {
        toast.success("Order submitted successfully!");
      } else if (FAILURE_STATUS_CODE.includes(res.status)) {
        toast.error(res.data.errorDesc || "Failed to submit order.");
      }
    });
  }, [order_id]);

  const toggleFeedbackModal = useCallback(() => {
    setFeedbackPopupOpen((prevState) => !prevState);
  }, []);

  const handleFeedbackSubmit = useCallback((feedbackText) => {
    console.log("Feedback submitted:", feedbackText);
    const data = { description: feedbackText };
    apiTokenQueries("POST", `order/prop-id/${order_id}/feedback`, data).then(
      (res) => {
        if (SUCCESS_STATUS_CODE.includes(res.status)) {
          toast.success("Feedback received, Our team will get back to you");
        } else if (FAILURE_STATUS_CODE.includes(res.status)) {
          toast.error("System error. Please write us to info@mapnexa.com");
        }
      }
    );

    setFeedbackPopupOpen(false);
  }, [order_id]);

  useEffect(() => {
    if (!map) return;

    const hoverInteraction = new Select({
      condition: pointerMove,
      style: null,
    });

    const handleSelect = (event) => {
      const selected = event.selected[0];
      handleMapHover(selected);
    };

    hoverInteraction.on("select", handleSelect);
    map.addInteraction(hoverInteraction);

    return () => {
      hoverInteraction.un("select", handleSelect);
      map.removeInteraction(hoverInteraction);
    };
  }, [map, handleMapHover]);

  const totalPolygonArea = useMemo(() => {
    return groupedFeatures.polygon
      ? groupedFeatures.polygon.reduce(
          (total, geojson) =>
            total +
            geojson.data.reduce(
              (subTotal, measurement) =>
                subTotal + parseFloat(measurement.measurement),
              0
            ),
          0
        )
      : 0;
  }, [groupedFeatures]);

  return (
    <div className={classes.MeasurementContainer}>
      <div className={classes.FeatureHeading}>
        <VisibilityIcon
          className={classes.VisibilityContainerFeature}
          fontSize="small"
          onClick={handleAllLayerVisibility}
        />
        <span className={classes.feature}>Feature</span>
        <span className={classes.measurement}>Measurement</span>
      </div>
      <hr className={classes.BottomLine} />

      {totalPolygonArea > 0 && (
        <div className={classes.TotalAreaContainer}>
          <Typography variant="h7" className={classes.TotalAreaHeading}>
            Total Area (sqft)  <div className={classes.TotalArea}>{totalPolygonArea.toFixed(2)}</div>
          </Typography>
        </div>
      )}
      {["polygon", "line", "point"].map((type) => (
        sortedGroupedFeatures[type]?.length > 0 && (
          <TypeGroup
            key={type}
            type={type}
            features={sortedGroupedFeatures[type]}
            openAccordionId={openAccordionId}
            toggleAccordion={toggleAccordion}
            handleGeojsonVisibilityChange={handleGeojsonVisibilityChange}
            handleColorChange={handleColorChange}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            hoveredFeatureId={hoveredFeatureId}
          />
        )
      ))}
      {addFeedback === true ? (
        <button
          className={classes.feedbackButton}
          onClick={toggleFeedbackModal}
        >
          Feedback
        </button>
      ) : addFeedback === "submitOrder" ? (
        <button onClick={confirmOrder}>Submit Order</button>
      ) : null}

      {isFeedbackPopupOpen && (
        <div className={classes.ModalContainer}>
          <div className={classes.ModalOverlay} onClick={toggleFeedbackModal} />
          <div className={classes.ModalContent}>
            <FeedbackForm onSubmit={handleFeedbackSubmit} order_id={order_id} />
          </div>
        </div>
      )}
    </div>
  );
};

const TypeGroup = React.memo(({
  type,
  features,
  openAccordionId,
  toggleAccordion,
  handleGeojsonVisibilityChange,
  handleColorChange,
  handleMouseEnter,
  handleMouseLeave,
  hoveredFeatureId,
}) => {
  return (
    <div key={type} className={classes.TypeGroup}>
      <Typography variant="h8" className={classes.TypeHeading}>
        <div className={classes.TypeHeadingContent}>
          <span className={classes.TypeLabel}>
            {type === "polygon"
              ? "Surface"
              : type === "line"
              ? "Edge"
              : type === "point"
              ? "Point"
              : ""}
          </span>
          <span className={classes.MeasurementLabel}>
            {type === "polygon"
              ? "Area (sqft)"
              : type === "line"
              ? "Length (ft)"
              : type === "point"
              ? "Count"
              : ""}
          </span>
        </div>
      </Typography>
      {features.map((geojson) => (
        <div
          className={classes.AccordianMainContainer}
          key={geojson.id}
        >
          <div className={classes.HeaderContainer}>
            <div className={classes.ColorBoxContainer}>
              <input
                type="color"
                value={geojson.color}
                className={classes.ColorBox}
                onChange={(e) =>
                  handleColorChange(geojson, e.target.value)
                }
              />
            </div>
            <div
              className={classes.VisibilityContainer}
              onClick={() => handleGeojsonVisibilityChange(geojson)}
            >
              {geojson.visible ? (
                <VisibilityIcon fontSize="small" />
              ) : (
                <VisibilityOffIcon fontSize="small" />
              )}
            </div>
            <div
              className={classes.NameDropDownContainer}
              onClick={() => toggleAccordion(geojson.id)}
            >
              <Typography className={classes.FeatureName}>
                {geojson.name}
              </Typography>
              <div className={classes.DropdownMeasurement}>
                <Typography className={classes.FeatureName}>
                  {geojson.type === "point"
                    ? geojson.data.length
                    : geojson.data
                        .reduce(
                          (total, measurement) =>
                            total + parseFloat(measurement.measurement),
                          0
                        )
                        .toFixed(2)}
                </Typography>
                <Typography>
                  {geojson.type === "line"
                    ? ""
                    : geojson.type === "polygon"
                    ? ""
                    : geojson.type === "point"
                    ? ""
                    : null}
                </Typography>
                <ExpandMoreIcon
                  className={
                    geojson.id === openAccordionId
                      ? classes.ExpandOpen
                      : classes.ExpandClosed
                  }
                />
              </div>
            </div>
          </div>
          {geojson.id === openAccordionId && (
            <div className={classes.ContentContainer}>
              <ul className={classes.MeasurementDropDown}>
                <li className={classes.CheckboxListHeader}>
                  <label className={classes.LabelHeader}>ID</label>
                  <label className={classes.LabelHeader}>
                    {geojson.type === "line"
                      ? "Length (ft)"
                      : geojson.type === "polygon"
                      ? "Area (sqft)"
                      : geojson.type === "point"
                      ? "Count"
                      : "Measurement"}
                  </label>
                </li>
                {geojson.data.map((measurement) => (
                  <li
                    key={measurement.id}
                    className={`${classes.CheckboxListItem} ${
                      hoveredFeatureId === measurement.id
                        ? classes.HighlightedRow
                        : ""
                    }`}
                    onMouseEnter={() =>
                      handleMouseEnter(measurement, geojson)
                    }
                    onMouseLeave={handleMouseLeave}
                  >
                    <p>
                      <label className={classes.LabelHeaderId}>
                        {measurement.newId}
                      </label>
                      <label className={classes.LabelHeaderMeasurement}>
                        {geojson.type === "point"
                          ? "1"
                          : measurement.measurement}
                      </label>
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
});

export default React.memo(GeoJSONAccordion);
