import React, { useEffect, useState } from 'react';
import classes from  "./MultiCheckBox.module.css"

const MultiCheckbox = ({ options, setFeatureCount }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);


  const handleCheckboxChange = (optionValue) => {
    if (selectedOptions.length >0){
      if (selectedOptions.includes(optionValue)) {
        setSelectedOptions(selectedOptions.filter(value => value !== optionValue));
      } else {
        setSelectedOptions([...selectedOptions, optionValue]);
      }
    }
    else{
      setSelectedOptions([optionValue]);
    }
    
  };

  useEffect(() => {
    setFeatureCount(selectedOptions.length)
  },[selectedOptions]);

  useEffect(() => {
    const selectedFeature = []
    options.map(singleOption =>{
      selectedFeature.push(singleOption.id)
    })
    setFeatureCount(selectedOptions.length)
    setSelectedOptions(selectedFeature);
  },[options]);

  return (
    <div className={classes.optionSelect}>
      {options.map(option => (
        <label key={option.id}>
          <input
            type="radio"
            value={option.id}
            checked={selectedOptions.includes(option.id)}
            onChange={() => handleCheckboxChange(option.id)}
          />
          {option.name}
        </label>
      ))}
    </div>
  );
};

export default MultiCheckbox;
