// CustomizeFeature.js
import React, { useState, useEffect, useRef } from 'react';
import classes from './CustomizeFeature.module.css';
import MultiCheckBoxCustom from "../MultiCheckBoxCustom/MultiCheckBoxCustom";
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function CustomizeFeature({options, setFeatureCount, totalFeatureList, setFeatureList,setcommonAreaPlace, commonAreaPlace,curbStripPlace,setcurbStripPlace,frontYardPlace,setfrontYardPlace }) {
  const ref = useRef();
  
  const [showModal, setShowModal] = useState(false);
  const [showHOAModal, setShowHOAModal] = useState(false);
  const [customSelection, setCustomSelection] = useState([]);
  const [selection, setSelection] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [commonArea, setCommonArea] = useState(commonAreaPlace);
  const [curbStrip, setcurbStrip] = useState(curbStripPlace);
  const [frontYard, setfrontYard] = useState(frontYardPlace);
  

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };


  const openModal = () => {
    setShowModal(true);
  };

  const openHOAModal = () => {
    setShowHOAModal(true);
  };


  const closeModal = () => {
    setShowModal(false);
    setSelection(false)
  };

  const SaveAndClose = () => {
    setFeatureList(customSelection)
    setShowModal(false);
    setSelection(false)
  }

  const clearSelection = () =>{
    setSelection(true)

  }

  const closeHOAModel = () =>{

    setShowHOAModal(false)
  }

  const saveHOADetails = () =>{
    setcommonAreaPlace(commonArea)
    setcurbStripPlace(curbStrip)
    setfrontYardPlace(frontYard)
    setShowHOAModal(false)

  }

  return (
    <div className={classes.CustomField} style={{ fontSize: "0.8rem" }}>
      <div>
        <div style={{ cursor: "pointer" }}>
          <button
            style={{
              fontSize: "0.8rem",
              backgroundColor: "#3ca364",
              color: "white",
            }}
            onClick={openHOAModal}
          >
            HOA Specification
          </button>

          {showHOAModal === true && (
            <div className={classes.overlay}>
              <div className={classes.modal}>
                <div className={classes.HOA}>
                  <div class={classes.MessageContainer}>
                    <div class={classes.ExclamationIcon}>!</div>
                    <div class={classes.ExclamationMessage}>
                      Price will be reduced by 35% if HOA is included.
                    </div>
                  </div>
                  <div className={classes.MeasureCommonAreaOnly}>
                    <div className={classes.CommonAreaHOA}>
                      <label className={classes.SingleOptions}>
                        <input
                          type="checkbox"
                          checked={commonArea}
                          onChange={() => setCommonArea(!commonArea)}
                        />
                        Measure Common area only
                      </label>
                    </div>
                    <div className={classes.OptionHOA}>
                      <label className={classes.SingleOptions}>
                        <input
                          type="checkbox"
                          checked={curbStrip}
                          disabled={!commonArea}
                          onChange={() => setcurbStrip(!curbStrip)}
                        />
                        Exclude Curb-strips
                      </label>
                      <label className={classes.SingleOptions}>
                        <input
                          type="checkbox"
                          checked={frontYard}
                          disabled={!commonArea}
                          onChange={() => setfrontYard(!frontYard)}
                        />
                        Include Front Yards
                      </label>
                    </div>
                  </div>
                </div>
                <div className={classes.CustomizeBtn}>
                  <button className={classes.cancel} onClick={closeHOAModel}>
                    Cancel
                  </button>
                  <button className={classes.save} onClick={saveHOADetails}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* {showModal && (
            <div className={classes.HOA}  style={{position: 'absolute', top: '28%' ,left: '18%', transform: 'translateX(-15%)', backgroundColor: 'white', color: '#000', 
              paddingTop: '5%',display:'flex', flexDirection: 'column', paddingBottom: '5%', borderRadius: '4px', zIndex:'10000', width: '90%', height : 'auto', textAlign: 'center' }} >
                <label className={classes.SingleOptions} >
                  <input type="checkbox"/>
                    Measure Common area only
                </label>
                <label className={classes.SingleOptions} >
                  <input type="checkbox"/>
                    Exclude Curb-strips
                </label>
                <label className={classes.SingleOptions} >
                  <input type="checkbox"/>
                    Include Front Yards
                </label>
                <div className={classes.CustomizeBtnHoa}>
                  <button className={classes.cancel} >
                    Cancel
                  </button>
                  <button className={classes.save} >
                    Save
                  </button>
                </div> 
            </div>
          )}                          */}
        </div>
      </div>

      <div>
        <button
          style={{
            fontSize: "0.8rem",
            backgroundColor: "#3ca364",
            color: "white",
          }}
          onClick={openModal}
        >
          Customize Feature
        </button>
        {showModal && (
          <div className={classes.overlay}>
            <div className={classes.modal}>
              <span className={classes.close} onClick={closeModal}>
                &times;
              </span>
              <MultiCheckBoxCustom
                options={options}
                setFeatureCount={setFeatureCount}
                totalFeatureList={totalFeatureList}
                setCustomSelection={setCustomSelection}
                selection={selection}
                setSelection={setSelection}
              />
              <div className={classes.CustomizeBtn}>
                <button className={classes.cancel} onClick={closeModal}>
                  Cancel
                </button>
                <button className={classes.cancel} onClick={clearSelection}>
                  Uncheck All
                </button>
                <button className={classes.save} onClick={SaveAndClose}>
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomizeFeature;
