// FeedbackForm.js

import React, { useState } from 'react';
import classes from "./Feedback.module.css";
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { apiTokenQueries } from '../../utils/ApiCall';

const FeedbackForm = ({ onSubmit, order_id }) => {
  const [feedback, setFeedback] = useState('');

  const handleSubmit = () => {
    onSubmit(feedback);
    setFeedback('');
  };

  return (
    
    <Box style={{textAlign:'center'}} >
      <Typography variant="h6" gutterBottom>
        Share Your Feedback
      </Typography>
      <TextareaAutosize className={classes.feedbackText}
        placeholder="Enter your feedback..."
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        minRows={4}
        maxRows={8}
        
      />
      <Button variant="contained" style={{ backgroundColor:'#14261c'}} onClick={handleSubmit}>
        Submit
      </Button>
    </Box>
  );
};

export default FeedbackForm;
