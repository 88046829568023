import {
  Combobox,
  ComboboxList,
  ComboboxInput,
  ComboboxOption,
  ComboboxPopover,
} from '@reach/combobox';
import React from 'react';
import './AddressSearch.css';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useLocation, useNavigate } from 'react-router-dom';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { useAddressContext } from '../../context/AddressContext';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Button } from '@mui/material';
export default function AddressSearch() {
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ['us', 'CA'] },
    },
  });
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const { setAddress, setLatLng } = useAddressContext();

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    setAddress(address);
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setLatLng([lng, lat]);
    if (path == '/home' || path == '/order') {
      navigate('/order');
    }
    clearSuggestions();
  };

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter' && value) {
      handleSelect(value);
    }
  };

  const handleClick = () => {
    if (value) {
      handleSelect(value);
    }
  };


  return (
    <div className="takeoff-outer-container">

<div className="arial-takeoff">
        Aerial takeOff
      </div>
    <Combobox onSelect={handleSelect} className="search-outer-container">
      <div className="search">
        <SearchOutlinedIcon className="search-icon" />
        <ComboboxInput
          value={value}
          onChange={handleInput}
          placeholder="Search Address or Lat Long"
          onKeyPress={handleKeyPress}
        />
         <Button variant="contained" fullWidth className="search-btn" onClick={handleClick}>Search</Button>
      </div>
      <ComboboxPopover className="search-suggestions-container">
        <ComboboxList className="search-suggestions-list">
          {status === 'OK' &&
            data.map((item, index) => (
              <div key={index} className="search-suggestions-item">
                <FmdGoodIcon />
                <ComboboxOption key={item.id} value={item.description} />
              </div>
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>

    </div>
    
  );
}
