import axios from 'axios';
import './TransactionTable.css';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import AuthToken from '../../context/AuthToken';
import React, { useEffect, useState } from 'react';
import { TEAM_DETAILS_URL } from '../../helper/ApiUrl';
import TableContainer from '@mui/material/TableContainer';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const TransactionTable = ({
  type,
  userId,
  setType,
  menuBar,
  setUserId,
  addToRefs,
  setMenuBar,
  setEndDate,
  setStartDate,
  transactions,
  handleUserFilter,
  handleDateFilter,
  handleTypeFilter,
}) => {
  const token = AuthToken();
  const [usersDetails, setUsersDetails] = useState([]);

  const getUsersDetails = () => {
    axios
      .get(TEAM_DETAILS_URL, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setUsersDetails(response.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getUsersDetails();
  }, []);

  const handleArrowClickBar = (val) => {
    if (menuBar.length) {
      if (menuBar[0] === val) {
        setMenuBar({});
      } else {
        setMenuBar([val]);
      }
    } else setMenuBar([val]);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="crdit-table-heading">Order Id</TableCell>
            <TableCell className="crdit-table-heading" ref={addToRefs}>
              <div
                className="order-cell-heading-div"
                onClick={() => handleArrowClickBar('NAME_DROPDOWN')}
              >
                <span>Created By</span>
                {/* {menuBar[0] === 'NAME_DROPDOWN' ? (
                  <KeyboardArrowUpIcon className="order-filter-icon" />
                ) : (
                  <KeyboardArrowDownIcon className="order-filter-icon" />
                )} */}
              </div>
              {/* {menuBar[0] === 'NAME_DROPDOWN' ? (
                <div className="order-status-filter credit-name-filter">
                  <h4>User Name</h4>
                  {usersDetails &&
                    usersDetails.map((user) => {
                      return (
                        <div
                          className="order-status-filter-one"
                          onClick={() => {
                            if (userId.includes(`${user.id}`)) {
                              setUserId(
                                userId.filter((e) => e !== `${user.id}`)
                              );
                            } else {
                              setUserId([...userId, `${user.id}`]);
                            }
                          }}
                        >
                          <input
                            checked={
                              userId.includes(`${user.id}`) ? true : false
                            }
                            name={user.id}
                            type="checkbox"
                            onChange={(e) => {
                              if (userId.includes(`${user.id}`)) {
                                setUserId(
                                  userId.filter((e) => e !== `${user.id}`)
                                );
                              } else {
                                setUserId([...userId, `${user.id}`]);
                              }
                            }}
                          />
                          <span>{user.name}</span>
                        </div>
                      );
                    })}
                  <hr />
                  <button onClick={handleUserFilter}>✔ Apply</button>
                </div>
              ) : null} */}
            </TableCell>
            <TableCell className="crdit-table-heading" ref={addToRefs}>
              <div
                className="order-cell-heading-div"
                onClick={() => handleArrowClickBar('DATE_DROPDOWN')}
              >
                <span> Created At.</span>
                {/* {menuBar[0] === 'DATE_DROPDOWN' ? (
                  <KeyboardArrowUpIcon className="order-filter-icon" />
                ) : (
                  <KeyboardArrowDownIcon className="order-filter-icon" />
                )} */}
              </div>
              {/* {menuBar[0] === 'DATE_DROPDOWN' ? (
                <div className="order-date-filter credit-date-filter">
                  <h4>Date</h4>
                  <div className="order-date-filter-one">
                    <span>From</span>
                    <input
                      type="date"
                      onChange={(event) =>
                        setStartDate({ startDate: event.target.value })
                      }
                    />
                  </div>
                  <div className="order-date-filter-two">
                    <span>To</span>
                    <input
                      type="date"
                      onChange={(event) =>
                        setEndDate({ endDate: event.target.value })
                      }
                    />
                  </div>
                  <hr />
                  <button onClick={handleDateFilter}>✔ Apply</button>
                </div>
              ) : null} */}
            </TableCell>
            <TableCell className="crdit-table-heading">Amount</TableCell>
            <TableCell className="crdit-table-heading crdit-table-heading-dis">
              Description
            </TableCell>
            <TableCell className="crdit-table-heading" ref={addToRefs}>
              <div
                className="order-cell-heading-div"
                onClick={() => handleArrowClickBar('TYPE_DROPDOWN')}
              >
                <span>Transaction Type</span>
                {/* {menuBar[0] === 'TYPE_DROPDOWN' ? (
                  <KeyboardArrowUpIcon className="order-filter-icon" />
                ) : (
                  <KeyboardArrowDownIcon className="order-filter-icon" />
                )} */}
              </div>
              {/* {menuBar[0] === 'TYPE_DROPDOWN' ? (
                <div className="order-status-filter trans-type-filter">
                  <h4>Transaction</h4>
                  <div
                    className="order-status-filter-one"
                    onClick={() => {
                      if (type.includes('CREDIT')) {
                        setType(type.filter((e) => e !== 'CREDIT'));
                      } else {
                        setType([...type, 'CREDIT']);
                      }
                    }}
                  >
                    <input
                      name="CREDIT"
                      type="checkbox"
                      checked={type.includes('CREDIT') ? true : false}
                      onChange={() => {
                        if (type.includes('CREDIT')) {
                          setType(type.filter((e) => e !== 'CREDIT'));
                        } else {
                          setType([...type, 'CREDIT']);
                        }
                      }}
                    />
                    <span>CREDIT</span>
                  </div>
                  <div
                    className="order-status-filter-two"
                    onClick={() => {
                      if (type.includes('DEBIT')) {
                        setType(type.filter((e) => e !== 'DEBIT'));
                      } else {
                        setType([...type, 'DEBIT']);
                      }
                    }}
                  >
                    <input
                      type="checkbox"
                      name="DEBIT"
                      checked={type.includes('DEBIT') ? true : false}
                      onChange={() => {
                        if (type.includes('DEBIT')) {
                          setType(type.filter((e) => e !== 'DEBIT'));
                        } else {
                          setType([...type, 'DEBIT']);
                        }
                      }}
                    />
                    <span>DEBIT</span>
                  </div>
                  <hr />
                  <button onClick={handleTypeFilter}>✔ Apply</button>
                </div>
              ) : null} */}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions &&
            transactions.map((transaction) => {
              return (
                <TableRow key={transaction.order_id}>
                  <TableCell className="crdit-table-cell">
                    {transaction.order_id === null
                      ? '__'
                      : transaction.order_id}
                  </TableCell>
                  <TableCell className="crdit-table-cell">
                    {transaction.created_by}
                  </TableCell>
                  <TableCell className="crdit-table-cell">
                    {transaction.created_at.slice(0, 10)}
                  </TableCell>
                  <TableCell className="crdit-table-cell">
                    {Math.abs(transaction.amount)}
                  </TableCell>
                  <TableCell className="crdit-table-cell">
                    {transaction.description}
                  </TableCell>
                  <TableCell
                    className="crdit-table-cell"
                    style={
                      transaction.transaction_type === 'CREDIT'
                        ? { color: 'green' }
                        : { color: 'red' }
                    }
                  >
                    {transaction.transaction_type}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionTable;
