import React, { useContext, useState, createContext } from "react";

const TokenContext = createContext();

export function TokenContextProvider({ children }) {
  const [authToken, setAuthToken] = useState("");
  const [role, setRole] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  return (
    <TokenContext.Provider
      value={{
        authToken,
        setAuthToken,
        rememberMe,
        setRememberMe,
        role,
        setRole
      }}
    >
      {children}
    </TokenContext.Provider>
  );
}

export function useTokenContext() {
  const context = useContext(TokenContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
