import React, { useEffect, useState } from "react";
import classes from "./FilterSearch.module.css";
import FilterSearchBox from "../FilterSearchContainer/FilterSearchContainer";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { apiTokenQueries } from "../../utils/ApiCall";
import {
  SUCCESS_STATUS_CODE,
  FAILURE_STATUS_CODE,
} from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import DateRangePicker from "../DatePicker/DatePicker";
import toast from "react-hot-toast";
import Tooltip from "@material-ui/core/Tooltip";

function FilterSearch({
  status,
  addressSearch,
  setAddressSearch,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setShowFilterPopUp,
  startDate,
  endDate,
}) {
  const [orderRows, setOrderRows] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [selectedFeature, setSelectedFeature] = useState(null);

  const navigate = useNavigate();

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  useEffect(() => {
    const baseUrl = "order/user";
    const queryParams = {};

    queryParams.p = currentPage;
    queryParams.psz = itemsPerPage;

    if (status === "draft") {
      queryParams.status = "draft";
    }
    if (status === "in_progress") {
      queryParams.status = "in_progress";
    }
    if (status === "measurement_completed") {
      queryParams.status = "measurement_completed";
    }
    if (startDate) {
      const date = new Date(startDate);
      const finalStartDate = [
        date.getFullYear(),
        ("0" + (date.getMonth() + 1)).slice(-2),
        ("0" + date.getDate()).slice(-2),
      ].join("-");
      queryParams.start_date = finalStartDate;
    }

    if (endDate) {
      const date = new Date(endDate);
      const finalEndDate = [
        date.getFullYear(),
        ("0" + (date.getMonth() + 1)).slice(-2),
        ("0" + date.getDate()).slice(-2),
      ].join("-");
      queryParams.end_date = finalEndDate;
    }
    if (addressSearch.length >= 1) {
      queryParams.address = addressSearch;
    }

    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&");

    const apiUrl = `${baseUrl}?${queryString}`;

    apiTokenQueries("GET", apiUrl).then((res) => {
      if (SUCCESS_STATUS_CODE.includes(res.status)) {
        const orderData = res.data.data;
        console.log("Order Data:", orderData);

        setTotalPages(res.data.total_pages);
        let tableData = [];
        orderData.map((data) => {
          console.log(data);
          tableData.push({
            id: data.id,
            order_id: data.order_id,
            address: data.address,
            status: data.status,
            created_at: data.created_at,
            area: data.area,
            takeofftype: data.takeofftype || "N/A", // Check for takeofftype and provide a fallback value if not present
          });
        });
        setOrderRows(tableData);
      } else if (FAILURE_STATUS_CODE.includes(res.status)) {
        toast.error(res.data.errorDesc);
      }
    });
  }, [status, addressSearch, currentPage, startDate, endDate]);

  return (
    <>
      <div className={classes.TableWholeContainer}>
        {orderRows ? (
          <div className={classes.MainContainer}>
            <div className={classes.FilterContainer}>
              <FilterSearchBox
                addressSearch={addressSearch}
                setAddressSearch={setAddressSearch}
              />
              <div
                className={classes.SingleFilterContainer}
                onClick={() => {
                  setShowFilterPopUp(true);
                }}
              >
                <FilterAltIcon />
                Filter
                {<ArrowDropDownIcon />}
              </div>
            </div>
            <div className={classes.TableOuterContainer}>
              <TableContainer
                className={classes.TableContainer}
                component={Paper}
              >
                <Table aria-label="customized table">
                  <TableHead className={classes.TableHead}>
                    <TableRow className={classes.TableRowOrder}>
                      <TableCell className={classes.TableRow}>
                        Order ID
                      </TableCell>
                      <TableCell className={classes.TableRow}>
                        Property Name
                      </TableCell>
                      <TableCell className={classes.TableRow}>
                        Created Date
                      </TableCell>
                      <TableCell className={classes.TableRow}>Area</TableCell>
                      <TableCell className={classes.TableRow}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.TableBody}>
                    {orderRows.map((row) => (
                      <TableRow
                        onClick={() => {
                          navigate(`/order/${row.order_id}`);
                        }}
                        className={classes.TableRowOrder}
                        key={row.order_id}
                      >
                        <TableCell className={classes.td} data-name="Order Id">
                          {row.id}
                        </TableCell>

                        <TableCell
                          className={classes.td}
                          data-name="Property Name"
                          component="th"
                          scope="row"
                        >
                          {row.address}
                        </TableCell>

                        <TableCell
                          className={classes.td}
                          data-name="Created Date"
                        >
                          {row.created_at.split("T")[0]}
                        </TableCell>
                        <TableCell className={classes.td} data-name="Area">
                          {row.area} Acre
                        </TableCell>
                        <TableCell
                          className={
                            row.status === "draft"
                              ? classes.DraftOrder
                              : row.status === "in_progress"
                              ? classes.OngoingOrder
                              : row.status === "measurement_completed"
                              ? classes.CompletedOrder
                              : classes.UnknownOrder
                          }
                        >
                          {row.status === "draft"
                            ? "Draft"
                            : row.status === "in_progress"
                            ? "In Progress"
                            : row.status === "measurement_completed"
                            ? "Completed"
                            : "Unknown"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        ) : null}
      </div>
      <ReactPaginate
        pageCount={totalPages}
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        onPageChange={handlePageChange}
        containerClassName={classes.pagination}
        activeClassName={classes.active}
        previousLabel={"Previous"} // Custom label for "Previous" button
        nextLabel={"Next"} // Custom label for "Next" button
        previousClassName={
          currentPage === 0 || currentPage === undefined ? classes.disabled : ""
        }
        nextClassName={currentPage === totalPages - 1 ? classes.disabled : ""}
      />
    </>
  );
}

export default FilterSearch;
