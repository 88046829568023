import React, {useState} from "react";
import OuterForm from "../../components/OuterForm/OuterForm";
import VerifyOtp from "../../components/VerifyOtp/VerifyOtp";
import OtpVerified from "../../components/OtpVerified/OtpVerified";

export default function Otp() {

    const [otpEntered, setOtpEntered] = useState(false)

    return (
        <div className="outer-container">
            <OuterForm>
            {/* <VerifyOtp setOtpEntered={setOtpEntered}/> */}
                {otpEntered === true ? <OtpVerified /> : <VerifyOtp setOtpEntered={setOtpEntered}/>}
            </OuterForm>

        </div>
    )
}