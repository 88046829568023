import './index.css';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './helper/ErrorBoundary';
import '@fortawesome/fontawesome-free/css/all.css';
import { OTPContextProvider } from './context/OTPContext';
import { LoaderProvider } from './context/LoaderProvider';
import { UserContextProvider } from './context/UserContext';
import { TokenContextProvider } from './context/TokenContext';
import { EmailContextProvider } from './context/EmailContext';
import { LayerContextProvider } from './context/LayerContext';
import { OrderContextProvider } from './context/OrderContext';
import { AddressContextProvider } from './context/AddressContext';
import { FeedbackContextProvider } from './context/FeedbackContext';

// LogRocket.init(`${process.env.REACT_APP_ROCKET_ID}`);

const RootComponent = () => (
  <React.StrictMode>
    <TokenContextProvider>
      <EmailContextProvider>
        <OTPContextProvider>
          <LoaderProvider>
            <AddressContextProvider>
              <OrderContextProvider>
                <LayerContextProvider>
                  <FeedbackContextProvider>
                    <BrowserRouter>
                      <UserContextProvider>
                        <ErrorBoundary>
                          <App />
                        </ErrorBoundary>
                      </UserContextProvider>
                    </BrowserRouter>
                  </FeedbackContextProvider>
                </LayerContextProvider>
              </OrderContextProvider>
            </AddressContextProvider>
          </LoaderProvider>
        </OTPContextProvider>
      </EmailContextProvider>
    </TokenContextProvider>
  </React.StrictMode>
);

ReactDOM.render(<RootComponent />, document.getElementById('root'));

// LogRocket.getSessionURL((sessionURL) => {
//   // You can log the sessionURL or use it as needed
//   console.log('LogRocket session URL:', sessionURL);
// });

serviceWorker.unregister();
