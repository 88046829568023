import React from "react";
import { Navigate } from "react-router-dom";
import AuthToken from "../context/AuthToken";

const RedirectHomeRouter = () => {
  const auth = AuthToken();
  const role = localStorage.getItem("role");
  return <Navigate to="/" />
};

export default RedirectHomeRouter;
