// import Feature from "ol/Feature";
// import { Polygon } from "ol/geom";
// import * as turf from "@turf/turf";
// import { v4 as uuidv4 } from "uuid";
// import { Draw } from "ol/interaction";
// import * as olExtent from "ol/extent";
// import GeoJSON from "ol/format/GeoJSON";
// import parcelStyle from "../OLStyles/ParcelStyle";
// import calculateArea from "../OLFeatures/CalculateArea";
// import removeAllInteraction from "../OLFeatures/RemoveInteraction";
// import { TOAST_TYPE } from "../../utils/constants";

// const cutPolygon = (
//   map,
//   polygonList,
//   setPolygonList,
//   setParcelStyle,
//   handleSuccess
// ) => {
//   const draw = new Draw({
//     type: "Polygon",
//   });

//   removeAllInteraction(map);
//   map.addInteraction(draw);
//   setParcelStyle(parcelStyle);

//   draw.on("drawend", function (e) {
//     let olParser = new GeoJSON();
//     let drawnFeaturesArray = olParser.writeFeaturesObject([e.feature]);
//     var feature = e.feature;
//     var poly = feature.getGeometry().getCoordinates();
//     if (feature.getGeometry().getType() === "Polygon") {
//       var kinkedPoly = turf.polygon(poly);
//       var unkinkedPoly = turf.unkinkPolygon(kinkedPoly);
//       if (unkinkedPoly.features.length > 1) {
//         handleSuccess(
//           TOAST_TYPE.WARNING,
//           "Self intersecting polygon is not supported"
//         );
//         return false;
//       }
//     }
//     let drawnFeatureCoordinates =
//       drawnFeaturesArray.features[0].geometry.coordinates[0];
//     const drawnFeature = new Feature({
//       geometry: new Polygon([drawnFeatureCoordinates]),
//     });

//     const drawnPolygon = drawnFeature.getGeometry();

//     const extent1 = drawnFeature.getGeometry().getExtent();

//     map.getLayers().forEach((layer) => {
//       if (layer.get("title") && layer.get("title") === "Parcel") {
//         layer.getLayers().forEach((item) => {
//           item.getSource().forEachFeature((feature) => {
//             const extent2 = feature.getGeometry().getExtent();
//             if (olExtent.intersects(extent1, extent2)) {
//               const featureId = feature.id_;
//               const selectedPolygon = feature.getGeometry();
//               const firstPolygon = turf.polygon(
//                 selectedPolygon.getCoordinates()
//               );
//               const secondPolygon = turf.polygon(drawnPolygon.getCoordinates());
//               const intersection = turf.intersect(firstPolygon, secondPolygon);
//               if (intersection) {
//                 const finalPolygon = turf.difference(
//                   firstPolygon,
//                   intersection
//                 );

//                 if (!finalPolygon) {
//                   polygonList = polygonList.filter(
//                     (polygon) => polygon.id != featureId
//                   );
//                   setPolygonList(polygonList);
//                 }

//                 if (
//                   finalPolygon &&
//                   finalPolygon.geometry.type === "MultiPolygon"
//                 ) {
//                   polygonList = polygonList.filter(
//                     (polygon) => polygon.id != featureId
//                   );
//                   setPolygonList(polygonList);
//                   finalPolygon.geometry.coordinates.forEach((item, index) => {
//                     const cutData = {
//                       id: uuidv4(),
//                       data: item,
//                       visible: true,
//                       measurement: calculateArea(item),
//                     };
//                     polygonList.push(cutData);
//                     setPolygonList(polygonList);
//                   });
//                 } else if (
//                   finalPolygon &&
//                   finalPolygon.geometry.type === "Polygon"
//                 ) {
//                   polygonList = polygonList.filter(
//                     (polygon) => polygon.id != featureId
//                   );
//                   setPolygonList(polygonList);
//                   const olCoord = finalPolygon.geometry.coordinates;
//                   const length = finalPolygon.geometry.coordinates[0].length;
//                   const cutData = {
//                     id: featureId,
//                     data: olCoord,
//                     visible: true,
//                     measurement: calculateArea(olCoord),
//                   };
//                   if (length > 0) {
//                     polygonList.push(cutData);
//                     setPolygonList(polygonList);
//                   }
//                 }
//               }
//             }
//           });
//         });
//       }
//     });
//     setPolygonList([...polygonList]);
//     document.body.classList.remove("cut-cursor");
//   });
// };

// export default cutPolygon;


import * as turf from "@turf/turf";
import GeoJSON from "ol/format/GeoJSON";
import { Draw, Snap } from "ol/interaction";
import parcelStyle from "../OLStyles/ParcelStyle";
import calculateArea from "../OLFeatures/CalculateArea";
import removeAllInteraction from "../OLFeatures/RemoveInteraction";
import toast from "react-hot-toast";
import * as olExtent from "ol/extent";
import Feature from "ol/Feature";
import { Polygon } from "ol/geom";
import { v4 as uuidv4 } from "uuid";

const cutPolygon = (
  map,
  parcelRef,
  polygonList,
  setZoomLayer,
  setParcelEdit,
  setPolygonList,
  setParcelStyle,
) => {
  var source;

  map.getLayers().forEach((layer) => {
    if (layer.get("title") && layer.get("title") === "Parcel") {
      source = layer.getLayers().getArray()[0].getSource();
    }
  });

  const draw = new Draw({
    source: source,
    type: "Polygon",
  });

  const snap = new Snap({
    source: source,
  });

  setZoomLayer(false);
  setParcelEdit(false);
  removeAllInteraction(map);
  map.addInteraction(draw);
  setParcelStyle(parcelStyle);
  document.body.classList.add("cut-cursor");

  draw.on("drawend", function (e) {
    let olParser = new GeoJSON();
    let drawnFeaturesArray = olParser.writeFeaturesObject([e.feature]);
    var feature = e.feature;
    var poly = feature.getGeometry().getCoordinates();
    if (feature.getGeometry().getType() === "Polygon") {
      var kinkedPoly = turf.polygon(poly);
      var unkinkedPoly = turf.unkinkPolygon(kinkedPoly);
      if (unkinkedPoly.features.length > 1) {
        toast.warning("Self intersecting polygon is not supported")
        return false;

      }
    }
    let drawnFeatureCoordinates =
      drawnFeaturesArray.features[0].geometry.coordinates[0];
    const drawnFeature = new Feature({
      geometry: new Polygon([drawnFeatureCoordinates]),
    });

    const drawnPolygon = drawnFeature.getGeometry();

    const extent1 = drawnFeature.getGeometry().getExtent();

    map.getLayers().forEach((layer) => {
      if (layer.get("title") && layer.get("title") === "Parcel") {
        layer.getLayers().forEach((item) => {
          item.getSource().forEachFeature((feature) => {
            const extent2 = feature.getGeometry().getExtent();
            if (olExtent.intersects(extent1, extent2)) {
              const featureId = feature.id_;
              const selectedPolygon = feature.getGeometry();
              const firstPolygon = turf.polygon(
                selectedPolygon.getCoordinates()
              );
              const secondPolygon = turf.polygon(drawnPolygon.getCoordinates());
              const intersection = turf.intersect(firstPolygon, secondPolygon);
              if (intersection) {
                const finalPolygon = turf.difference(
                  firstPolygon,
                  intersection
                );

                if (!finalPolygon) {
                  polygonList = polygonList.filter(
                    (polygon) => polygon.id != featureId
                  );
                  setPolygonList(polygonList);
                }

                if (
                  finalPolygon &&
                  finalPolygon.geometry.type === "MultiPolygon"
                ) {
                  polygonList = polygonList.filter(
                    (polygon) => polygon.id != featureId
                  );
                  setPolygonList(polygonList);
                  finalPolygon.geometry.coordinates.forEach((item, index) => {
                    const cutData = {
                      id: uuidv4(),
                      data: item,
                      visible: true,
                      measurement: calculateArea(item),
                    };
                    polygonList.push(cutData);
                    setPolygonList(polygonList);
                  });
                } else if (
                  finalPolygon &&
                  finalPolygon.geometry.type === "Polygon"
                ) {
                  polygonList = polygonList.filter(
                    (polygon) => polygon.id != featureId
                  );
                  setPolygonList(polygonList);
                  const olCoord = finalPolygon.geometry.coordinates;
                  const length = finalPolygon.geometry.coordinates[0].length;
                  const cutData = {
                    id: featureId,
                    data: olCoord,
                    visible: true,
                    measurement: calculateArea(olCoord),
                  };
                  if (length > 0) {
                    polygonList.push(cutData);
                    setPolygonList(polygonList);
                  }
                }
              }
            }
          });
        });
      }
    });
    setPolygonList([...polygonList]);
    document.body.classList.remove('cut-cursor');
    document.body.classList.remove("delete-cursor");
  });
  if (polygonList.length > 0) {
    map.addInteraction(snap);
  }
};

export default cutPolygon;
