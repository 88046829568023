import React from 'react'
import './GeneratingReport.css'

const GeneratingReport = () => {
  return (
    <div className="generating-report">
      <div className="loading-spinner"></div>
      <div className="loading-text">Please wait, PDF is generating</div>
    </div>
  )
}

export default GeneratingReport