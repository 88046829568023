import './SettingsLeftBar.css';
import * as React from 'react';
import { useLocation, Link } from 'react-router-dom';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';

const SettingsLeftBar = ({ setSettingsAnchor }) => {
  const { pathname } = useLocation();

  const listItems = [
    {
      text: 'My Profile',
      icon: <ManageAccountsOutlinedIcon className="icon" />,
      path: '/setting/user',
    },
    {
      text: 'Organization',
      icon: <SettingsOutlinedIcon className="icon" />,
      path: '/setting/org',
    },
    {
      text: 'Credit Management',
      icon: <CreditCardOutlinedIcon className="icon" />,
      path: '/setting/credit',
    },
  ];

  return (
    <div className="settings-nav">
      {listItems.map((item) => {
        const { text, icon, path } = item;
        return (
          <Link
            onClick={() => setSettingsAnchor(null)}
            to={path}
            key={text}
            className={`settings-nav-item ${pathname === path ? 'active' : ''}`}
          >
            {icon}
            <span>{text}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default SettingsLeftBar;
