import { MultiPoint } from "ol/geom";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";

const selectedStyle = [
  new Style({
    stroke: new Stroke({
      color: "white",
      width: 3,
      lineDash: [10],
    }),
    fill: new Fill({
      color: "rgba(0, 0, 255, 0.1)",
    }),
  }),
  new Style({
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({
        color: "#F49D1A",
      }),
    }),
    geometry: function (feature) {
      const coordinates = feature.getGeometry().getCoordinates()[0];
      return new MultiPoint(coordinates);
    },
  }),
];

export default selectedStyle;
