import React, {useState, useContext} from 'react'
import { TextField, Button, Grid, Typography, Icon } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LockIcon from '@mui/icons-material/Lock';
import "./RegisterForm.css"
import Checkbox from '@mui/material/Checkbox';
// import ThirdPartyAuth from '../ThirdPartyAuth/ThirdPartyAuth';
import { useForm } from "react-hook-form";
import { apiQueries } from '../../utils/ApiCall';
import { SUCCESS_STATUS_CODE, FAILURE_STATUS_CODE } from '../../utils/constants';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from "react-router-dom";
import CompanyLogo from "../../assets/MapNexa.png"



function RegisterForm() {
    
    // const { updateContextValue } = useContext(EmailContext);
    const { register, handleSubmit,formState: { errors }, } = useForm();
    const navigate = useNavigate();

    const onSubmit = (event) =>{
      const data = {"email" : event.emailAddress, "password" : event.password, "first_name" : event.firstName, "contact_number" : event.mobileNumber}
      apiQueries("POST", "user/signup", data).then((res)=>{
        if(SUCCESS_STATUS_CODE.includes(res.status)){
          const url = `/otp?user_mail=${event.emailAddress}`
          navigate(url)
        }
        else if(FAILURE_STATUS_CODE.includes(res.status)){
          toast.error(res.data.errorDesc)
        }     
      })

    }

  return (
    <div className="register-section">
        <div className='register-header'>
        <img src={CompanyLogo} alt="CompanyLogo" className="CompanyLogo" />
        {/*<Typography className = "register-page-heading" variant="h5">Get Started With MapNexa</Typography>*/}
        </div>
        {/* <div className='third-party-auth-container'>
        <ThirdPartyAuth btnLabel = "Sign Up with Google" btnIcon = "/img/google.svg?url"/>
        <ThirdPartyAuth btnLabel = "Sign Up with LinkedIn" btnIcon = "/img/linkedin.svg?url"/>
        </div> */}
    
    <form onSubmit={handleSubmit(onSubmit)}>
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <TextField
      className='form-input-container'
        variant="outlined"
        style={{ backgroundColor: '#FFFFFF' }}
        fullWidth
        {...register("firstName", {required:true} )}
        InputProps={{
            startAdornment: (
              <Icon className='input__icon'>
                <PersonIcon  style={{ color: '#928989' }}/>
              </Icon>
            ),
          }}
          placeholder="Enter your full name"
          aria-invalid={errors.firstName ? "true" : "false"}
      />
       {errors.firstName?.type === "required" && (
        <p className ="error-msg" role="alert">First name is required</p>
      )}
    </Grid>
    <Grid item xs={12} >
      <TextField
      className='form-input-container'
        variant="outlined"
        style={{ backgroundColor: '#FFFFFF' }}
        fullWidth
        {...register("emailAddress", {
          required : true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address"
          }})}
        InputProps={{
            startAdornment: (
              <Icon className='input__icon'>
                <EmailIcon  style={{ color: '#928989' }}/>
              </Icon>
            ),
          }}
          placeholder="Enter your Email Address"
      />
       {errors.emailAddress?.type === "required" && (
        <p className ="error-msg" role="alert">Email is required</p>
      ) || errors.emailAddress?.type === "pattern" && (
        <p className ="error-msg" role="alert">{errors.emailAddress?.message}</p>
      )}
     
    </Grid>
    <Grid item xs={12}>
      <TextField
      className='form-input-container'
        variant="outlined"
        fullWidth
        style={{ backgroundColor: '#FFFFFF' }}
        {...register("mobileNumber", {
          required : true,
          pattern: {
            value: /^([+]\d{2})?\d{10}$/,
            message: "Invalid mobile Number"
          }})}
        InputProps={{
            startAdornment: (
              <Icon className='input__icon'>
                <PhoneIcon  style={{ color: '#928989' }}/>
              </Icon>
            ),
          }}
          placeholder="Enter your Mobile Number"
      />
      {errors.mobileNumber?.type === "required" && (
        <p className ="error-msg" role="alert">Email is required</p>
      ) || errors.mobileNumber?.type === "pattern" && (
        <p className ="error-msg" role="alert">{errors.mobileNumber?.message}</p>
      )}
    </Grid>
    <Grid item xs={12}>
      <TextField
      className='form-input-container'
        variant="outlined"
        type="password"
        fullWidth
        style={{ backgroundColor: '#FFFFFF' }}
        {...register("password", {required : true})}
        InputProps={{
            startAdornment: (
              <Icon className='input__icon'>
                <LockIcon  style={{ color: '#928989' }}/>
              </Icon>
            ),
          }}
          placeholder="Enter password"
      />
      {errors.password?.type === "required" && (
        <p className ="error-msg" role="alert">Password is required</p>
      )}
    </Grid>
  </Grid>
  <div className='privacy-container'>
      <Checkbox
      {...register('chooseCb', {
        required : true
      })} 
      sx={{
    color:'white',
    
  }}/>
      <p>I agree to Privacy Policy & Terms of Services </p>
    </div>
    {errors.chooseCb?.type === "required" && (
        <p className ="error-msg" role="alert">Please accept to terms and agreement</p>
      )}
  

  <Button type="submit" variant="contained" color="primary" fullWidth className='signup-btn'>
    Create an account
  </Button>
</form>
    <div className='login-redirect-container'>
    <p>Already have an account? <a href="/login" className='login-redirect'>Login here</a></p>
    </div>

        </div>
  )
}

export default RegisterForm