const handleLayerTypeSatellite = (map) => {
  map.getLayers().forEach((layer) => {
    if (layer.get("title") && layer.get("title") === "Road") {
      layer.values_.visible = false;
    } else if (layer.get("title") && layer.get("title") === "Satellite") {
      layer.values_.visible = true;
    }
  });
  map.getView().animate({});
};

export default handleLayerTypeSatellite;
