import React, { useState } from "react";
import styles from "./PopUp.module.css";
import DateRangePicker from "../DatePicker/DatePicker";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

const PopupBox = ({ setShowFilterPopUp, onApplyFilter }) => {
  const [status, setStatus] = useState(""); // Changed initial state to an empty string
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleChange = (event) => {
    setStatus(event.target.value);
    console.log("Status selected:", event.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    console.log("Start Date selected:", date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    console.log("End Date selected:", date);
  };

  const applyFilter = () => {
    console.log("Applying filter with:", { status, startDate, endDate });
    onApplyFilter({ status, startDate, endDate });
    setShowFilterPopUp(false);
  };

  const clearFilter = () => {
    setStatus("");
    setStartDate(null);
    setEndDate(null);
    onApplyFilter({ status: "", startDate: null, endDate: null });
    setShowFilterPopUp(false);
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.popup}>
        <button
          className={styles.closeButton}
          onClick={() => setShowFilterPopUp(false)}
        >
          &times;
        </button>
        <div className={styles.FilterContent}>
          <div className={styles.SingleFilter}>
            <span className="date">By Date: </span>
            <DateRangePicker
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
            />
          </div>

          <div className={styles.SingleFilterStatus}>
            <span>By Status: </span>
            <Select
              value={status}
              onChange={handleChange}
              className={styles.DropDown}
              displayEmpty
            >
              <MenuItem value="">
                <div>Select a status</div>
              </MenuItem>
              <MenuItem value={"draft"}>Draft</MenuItem>
              <MenuItem value={"in_progress"}>In Progress</MenuItem>
              <MenuItem value={"measurement_completed"}>
                Measurement Completed
              </MenuItem>
            </Select>
          </div>
          <div className={styles.ApplyContainer}>
            <Button
              
              className={styles.ClearBtn}
              onClick={clearFilter}
            >
              Clear Filter
            </Button>
            <Button
              variant="contained"
              className={styles.ApplyBtn}
              onClick={applyFilter}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupBox;
