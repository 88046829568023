import "./SettingsPage.css";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsLeftBar from "../../components/SettingsLeftBar/SettingsLeftBar";

const SettingsPage = () => {

  const navigate = useNavigate();
  const [settingsAnchor, setSettingsAnchor] = useState(null);
  const settingsOpen = Boolean(settingsAnchor);

  const logoutBtnClick = () => {
    localStorage.removeItem('authToken');
    sessionStorage.removeItem('authToken');
    localStorage.removeItem('role');
    navigate('/');
    window.location.reload(false);
  };

  return (
    <div className="settings-container">
      <div className="settings-top-container">
        <h2>Settings</h2>
        <MenuIcon className="settings-menu-icon mobile-menu-icon" onClick={(e) => setSettingsAnchor(e.currentTarget)} />
      </div>
      <SettingsLeftBar setSettingsAnchor={setSettingsAnchor} />
      <Drawer
        anchor='right'
        open={settingsOpen}
        onClose={() => setSettingsAnchor(null)}
      >
        <div className="settings-drawer">
          <div className="settings-drawer-content">
            <SettingsLeftBar setSettingsAnchor={setSettingsAnchor} />
          </div>
          <div className="settings-drawer-footer">
            <div className="settings-logout-btn" onClick={logoutBtnClick}>
              <LogoutIcon className="sidebar-icon" />
              <span>Logout</span>
            </div>
          </div>
        </div>
      </Drawer>
      <div className="settings-content">
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsPage;
