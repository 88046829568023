import React from "react";
import './Register.css'
import OuterForm from "../../components/OuterForm/OuterForm";
import RegisterForm from "../../components/RegisterForm/RegisterForm";

export default function Register() {
    return (
        <div className="outer-container">
            <OuterForm>
            <RegisterForm/>
            </OuterForm>

        </div>
    )
}