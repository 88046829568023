import {
  FILE_UPLOAD,
  INVITE_MEMBER,
  TEAM_DETAILS_URL,
  USER_ORG_CREATE_URL,
} from '../../helper/ApiUrl';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import './OrganizationSetting.css';
import Avatar from '@mui/material/Avatar';
import { Tooltip } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AuthToken from '../../context/AuthToken';
import { TOAST_TYPE } from '../../utils/constants';
import React, { useRef, useCallback } from 'react';
import { userPermission } from '../../helper/permissions';
import CircularProgress from '@mui/joy/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const OrganizationSetting = ({ handleError, handleSuccess }) => {
  const token = AuthToken();
  const outerRef = useRef();
  const innerRef = useRef();
  const maxFileSize = 10 * 1024 * 1024;
  const roleDropDownRef = useRef();
  const revokeInviteRef = useRef();
  const inviteInnerRef = useRef();
  const inviteOutterRef = useRef();
  const [name, setName] = useState('');
  const revokeInviteInnerRef = useRef();
  const [orgCode, setOrgCode] = useState();
  const role = localStorage.getItem('role');
  const [error, setError] = useState(false);
  const [orgLogo, setOrgLogo] = useState(null);
  const [orgName, setOrgName] = useState({});
  const [status, setStatus] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [invitationId, setInvitationId] = useState();
  const [usersDetails, setUsersDetails] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isProgressive, setIsProgressive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [invitedUsersList, setInvitedUsersList] = useState([]);
  const [revokeInvitation, setRevokeInvitation] = useState(false);
  const [inputFields, setInputFields] = useState([
    { value: '' },
    { value: '' },
    { value: '' },
  ]);

  const handleInputChange = (event, index) => {
    const value = event.target.value;
    setInputFields((prevState) => {
      const inputFields = [...prevState];
      inputFields[index].value = value;
      return inputFields;
    });
  };

  const handleAddInput = () => {
    setInputFields((prevState) => [...prevState, { value: '' }]);
  };

  const handleAccordianToggle = (num) => {
    if (selected === num) {
      return setSelected(null);
    }
    setSelected(num);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setOrgName({
      ...orgName,
      [name]: value,
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    axios
      .patch(USER_ORG_CREATE_URL, { org_logo: orgLogo, org_name: orgName.org_name }, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 204) {
          setStatus(true);
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const getOrgInfo = () => {
    axios
      .get(USER_ORG_CREATE_URL, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setName(response.data.data.org_name);
        setOrgCode(response.data.data.org_code);
        setOrgLogo(response.data.data.org_logo);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const getUsersDetails = () => {
    axios
      .get(TEAM_DETAILS_URL, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setUsersDetails(response.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const sendInvitation = () => {
    let emails = '';
    inputFields.map((item) => {
      emails += item.value + ',';
    });
    const emailsData = {
      emails: emails,
    };

    axios
      .post(INVITE_MEMBER, emailsData, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setRevokeInvitation(!revokeInvitation);
        setInputFields((prevState) =>
          prevState.map((inputField) => ({ value: '' }))
        );
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const getInvitedUsers = () => {
    axios
      .get(INVITE_MEMBER, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setInvitedUsersList(response.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getInvitedUsers();
  }, [revokeInvitation]);

  const resendInvite = (invitationId) => {
    axios
      .put(
        `${INVITE_MEMBER}/${invitationId}`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        }
      )
      .then((response) => {
        setRevokeInvitation(!revokeInvitation);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const revokeInvite = () => {
    axios
      .delete(`${INVITE_MEMBER}/${invitationId}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setRevokeInvitation(!revokeInvitation);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getOrgInfo();
    getUsersDetails();
  }, []);

  const changeRole = (memberId, memberRole) => {
    const roleData = { role: `${memberRole}` };
    axios
      .put(`${TEAM_DETAILS_URL}/${memberId}`, roleData, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        getUsersDetails();
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const handleClickOutSide = (e) => {
    if (innerRef.current) {
      if (!innerRef.current.contains(e.target)) {
        outerRef.current.style.opacity = 0;
        outerRef.current.style.pointerEvents = 'none';
      }
    }
    if (inviteInnerRef.current) {
      if (!inviteInnerRef.current.contains(e.target)) {
        inviteOutterRef.current.style.opacity = 0;
        inviteOutterRef.current.style.pointerEvents = 'none';
      }
    }
    if (revokeInviteInnerRef.current) {
      if (!revokeInviteInnerRef.current.contains(e.target)) {
        revokeInviteRef.current.style.opacity = 0;
        revokeInviteRef.current.style.pointerEvents = 'none';
      }
    }
    if (roleDropDownRef.current) {
      if (!roleDropDownRef.current.contains(e.target)) {
      } else {
      }
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 0) setErrorMessage("Select not more than 1 file");
    let file = acceptedFiles[0];
    if (file.size > maxFileSize)
      setErrorMessage("File size is greater than 10mb");
    else {
      setSelectedFile(file);
      setErrorMessage('');
    }
  }, []);

  const handleUpload = () => {
    if (selectedFile) {
      setIsProgressive(true);
      const formData = new FormData();
      formData.append('file', selectedFile);
      axios
        .post(FILE_UPLOAD, formData, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          if (response.data.url) {
            setOrgLogo(response.data.url);
            setSelectedFile(undefined);
            setIsProgressive(false);
            outerRef.current.style.opacity = 0;
            outerRef.current.style.pointerEvents = 'none';
            setIsUploaded(!isUploaded);
            handleSuccess(TOAST_TYPE.SUCCESS, 'Organization Logo Uploaded.');
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      handleSuccess(TOAST_TYPE.WARNING, 'Please choose file to upload!');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
    }
  });

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
  }, []);

  return (
    <div className="organization-setting-container">
      <div className="organization-setting">
        <div className="organization-setting-left">
          {/* <div className="organization-profile-pic">
            <div className="profile-pic-heading">
              <h4>Logo</h4>
            </div>

           
            <div className="profile-pic-content">
              <Avatar
                className="org-pic-image"
                alt="organization-logo"
                src={
                  orgLogo
                    ? orgLogo
                    : "https://cdn-icons-png.flaticon.com/512/6596/6596121.png"
                }
              />
              { <button
                className='org-logo-edit-button'
                onClick={() => {
                  outerRef.current.style.opacity = 1;
                  outerRef.current.style.pointerEvents = 'auto';
                }}>
                <EditIcon className="org-logo-edit-icon" />
              </button> }
                  
            </div>
          </div> */}
          <div className="organization-details">
            <form onSubmit={handleUpdate}>
              <div className="form-content">
                <div className="profile-pic-heading">
                  <h4>Name</h4>
                </div>
                <input
                  readOnly={
                    role === "user"
                      ? !userPermission.permissions.ORG_MODYFY
                      : false
                  }
                  className="formInput"
                  type="text"
                  name="org_name"
                  autoComplete="off"
                  defaultValue={name}
                  onChange={handleChange}
                />
                <div className="org-setting-container">
                  <button
                    type="submit"
                    disabled={
                      role === "user"
                        ? !userPermission.permissions.ORG_MODYFY
                        : false
                    }
                    className="saveButton"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div>
            {error && <span className="error">Something went wrong!</span>}
            {status && (
              <span className="success">
                Organization details have been updated!
              </span>
            )}
          </div>
          {/* <div className="invite-member">
            <div className="profile-pic-heading">
              <h4>Member</h4>
            </div>
            <p>
              By clicking on this button you can invite one or multiple members
              at once.
            </p>
            <button
              className="saveButton"
              disabled={
                role === 'user'
                  ? !userPermission.permissions.TEAM_MODYFY
                  : false
              }
              onClick={() => {
                inviteOutterRef.current.style.opacity = 1;
                inviteOutterRef.current.style.pointerEvents = 'auto';
              }}
            >
              Invite People
            </button>
          </div> */}
          <div className="org-code">
            <div className="profile-pic-heading">
              <h4>Code</h4>
            </div>
            <p>
              Your workspace code is <span>{orgCode}</span>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationSetting;
