import React, {useState} from 'react'
import classes from "./ResetPasswordForm.module.css"
import { TextField, Button, Typography, Icon, Link, Grid } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { apiTokenQueries } from '../../utils/ApiCall';
import { SUCCESS_STATUS_CODE, FAILURE_STATUS_CODE } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';


function ResetPasswordForm() {

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const navigate = useNavigate();

  const  handleSubmit = (event) =>{
    event.preventDefault()
    if (password !== confirmPassword){
      toast.error("Password does not match")
      return
    }
    const user_token = queryParams.get('user');
    const data = { token: user_token , password : password};
    apiTokenQueries('POST', 'user/reset-password', data).then((res) => {
      if (SUCCESS_STATUS_CODE.includes(res.status)) {
        if (res.data?.success) {
          navigate("/login");
        }
      } else if (FAILURE_STATUS_CODE.includes(res.status)) {
        toast.error(res.data.errorDesc);
      }
    });

}

  return (
    <div className={classes.ResetPasswordFormContainer}>
        <img src="/img/error_outline.svg?url" alt="" />
        <Typography className={classes.ResetHeading}>Forget Password</Typography>
        <Typography  className={classes.ResetDescription}>Enter your Email address and we will send you a link to reset your</Typography>
    <form className = {classes.ResetForm} onSubmit={handleSubmit}>
    <Grid container spacing={2}>
    <Grid item xs={12}>
    <TextField
      className='form-input-container'
        variant="outlined"
        style={{ backgroundColor: '#FFFFFF' }}
        fullWidth
        type="password"
        value={password}
        required
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
            startAdornment: (
              <Icon className='input__icon'>
                <LockIcon  style={{ color: '#928989' }}/>
              </Icon>
            ),
          }}
          placeholder="Enter new Password"
      />
    </Grid>
   
    <Grid item xs={12}>
    <TextField
      className='form-input-container'
        variant="outlined"
        style={{ backgroundColor: '#FFFFFF' }}
        fullWidth
        required
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        InputProps={{
            startAdornment: (
              <Icon className='input__icon'>
                <LockIcon  style={{ color: '#928989' }}/>
              </Icon>
            ),
          }}
          placeholder="Confirm Password"
      />

    </Grid>

  </Grid>
  <Button className={classes.SubmitBtn} type="submit" variant="contained">
    Submit
  </Button>

  
</form>

    </div>
  )
}

export default ResetPasswordForm