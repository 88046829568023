import Feature from "ol/Feature";
import { Style } from "ol/style";
import { toLonLat } from "ol/proj";
import { Polygon, LinearRing } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { createEmpty, extend, buffer } from "ol/extent";

const renderParcel = (
  polygonList,
  map,
  parcelRef,
  parcelStyle,
  zoomLayer,
  status,
  nearmapcoords,
  setNearmapcoords
) => {
  if (
    status === "measurement_completed" ||
    status === "in_progress" ||
    status === "diy"
  ) {
    parcelStyle[0].fill_.color_ = "transparent";
  }
  const drawnFeatures = [];
  if (polygonList.length)
    polygonList.map((polyData) => {
      polyData.data.map((item, index) => {
        if (index === 0) {
          const style = new Style({});
          const feature = new Feature({
            geometry: new Polygon([item]),
          });
          const new_polygon = feature.getGeometry();
          polyData.data.map((hole, reIndex) => {
            if (reIndex > 0) {
              const linearRing = new LinearRing(hole);
              new_polygon.appendLinearRing(linearRing);
            }
          });
          // if (!polyData.visible) {
          //   feature.setStyle(style);
          // }
          feature.setId(polyData.id);
          drawnFeatures.push(feature);
        }
      });
    });
  var extentOfAllFeatures = createEmpty();
  const x = [];
  drawnFeatures.map((feature, index) => {
    feature.set('visible', true);
    extend(extentOfAllFeatures, feature.getGeometry().getExtent());
    let coords = feature.getGeometry().getExtent();
    coords = buffer(coords, 10);
    let coords1 = toLonLat([coords[0], coords[1]]);
    let coords2 = toLonLat([coords[2], coords[3]]);
    if (coords1[0] && coords1[1] && coords2[0] && coords2[1]) {
      x.push([coords1[0], coords1[1], coords2[0], coords2[1]]);
    }
    // console.log(nearmapcoords)
  });
  setNearmapcoords([...x]);

  extentOfAllFeatures = buffer(extentOfAllFeatures, 40);

  if (zoomLayer) {
    if (extentOfAllFeatures[0] != "Infinity")
      map.getView().fit(extentOfAllFeatures, { padding: [0, 0, 0, 0] });
  }

  const polyLayer = new VectorLayer({
    source: new VectorSource({
      features: drawnFeatures,
    }),
    style: parcelStyle,
    title: "Draw",
  });
  parcelRef.current.getLayers().push(polyLayer);
  map.getLayers().forEach(function (layer) {
    if (layer && layer.get("title") === "Parcel") {
      map.removeLayer(layer);
    }
  });
  parcelRef.current.setZIndex(1);
  map.addLayer(parcelRef.current);
};

export default renderParcel;
