import Map from "ol/Map";
import View from "ol/View";
import XYZ from "ol/source/XYZ";
import TileLayer from "ol/layer/Tile";
import { defaults } from "ol/control/defaults";

const initializeMap = (mapElement, setMap) => {
  const initialMap = new Map({
    view: new View({
      zoom: 19,
      maxZoom: 24,
    }),
    layers: [
      new TileLayer({
        source: new XYZ({
          url: "http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}",
          crossOrigin: "Anonymous",
        }),
        visible: true,
        title: "Satellite",
      }),
      new TileLayer({
        source: new XYZ({
          url: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
          crossOrigin: "Anonymous",
        }),
        visible: false,
        title: "Road",
      }),
    ],
    target: mapElement.current,
    keyboardEventTarget: document,
    controls: defaults({
      zoom: false,
      attribution: false,
      rotate: false,
    }),
  });
  setMap(initialMap);
};

export default initializeMap;
