import axios from 'axios';
import GetAuthToken from './GetToken';

 const API_URL = "https://app.mapnexa.com/krypton/v1/api/"
 //const API_URL = "http://127.0.0.1:8000/krypton/v1/api/"

const apiTokenQueries = async (method, query, data) => {
    try {
      const userToken = GetAuthToken();
  
      const config = {
        method: method,
        url: `${API_URL}${query}`,
      };
  
      if (userToken) {
        config.headers = {
          Authorization: `Token ${userToken}`,
        };
      }
  
      if (data) {
        config.data = data;
      }
  
      const response = await axios(config);
      return response;
    } catch (error) {
        console.log("imperfect")
        return error.response;
    }
  };

  const apiTokenQueriesParam = async (method, query, data, token) => {
    try {

      const config = {
        method: method,
        url: `${API_URL}${query}`,
      };
  
      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
        };
      }
  
      if (data) {
        config.data = data;
      }
  
      const response = await axios(config);
      
      return response;
    } catch (error) {
        console.log("imperfect")
        return error.response;
    }
  };


  const apiQueries = async (method, query, data,) => {
    try {
    //   const userToken = await getTokenFromStorage();
  
      const config = {
        method: method,
        url: `${API_URL}${query}`,
      };

  
      if (data) {
        config.data = data;
      }
  
      const response = await axios(config);
      console.log("innerdata", response.data)
      return response;
    } catch (error) {
        console.log(error)
        return error.response;
    }
  };

  export {apiQueries, apiTokenQueries, apiTokenQueriesParam, API_URL}