import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classes from "./DatePicker.module.css"

function DateRangePicker({ onStartDateChange, onEndDateChange }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    onStartDateChange(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    onEndDateChange(date);
  };

  return (
    <div className={classes.DatePickerContainer}>
      <div className={classes.startDate}>
        <DatePicker 
          selected={startDate}
          onChange={handleStartDateChange}
          dateFormat="dd/MM/yyyy"
          isClearable
          placeholderText="Select start date"
        />
      </div>
      <div className={classes.endDate}>
        <DatePicker 
          selected={endDate}
          onChange={handleEndDateChange}
          dateFormat="dd/MM/yyyy"
          isClearable
          placeholderText="Select end date"
        />
      </div>
      {/* <p>
        Start Date: {startDate ? startDate.toDateString() : 'No date selected'}
        <br />
        End Date: {endDate ? endDate.toDateString() : 'No date selected'}
      </p> */}
    </div>
  );
}

export default DateRangePicker;
