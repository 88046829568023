import { Draw, Snap, Modify, Select } from "ol/interaction";

const removeAllInteraction = (map) => {  
  var drawInteraction = false;
  var snapInteraction = false;
  var selectInteraction = false;
  var modifyInteraction = false;
  map.getInteractions().forEach(function (interaction) {
    if (interaction instanceof Select) {
      selectInteraction = interaction;
    } else if (interaction instanceof Draw) {
      drawInteraction = interaction;
    } else if (interaction instanceof Snap) {
      snapInteraction = interaction;
    } else if (interaction instanceof Modify) {
      modifyInteraction = interaction;
    }
  });
  if (drawInteraction) map.removeInteraction(drawInteraction);
  if (snapInteraction) map.removeInteraction(snapInteraction);
  if (selectInteraction) map.removeInteraction(selectInteraction);
  if (modifyInteraction) map.removeInteraction(modifyInteraction);

};

export default removeAllInteraction;
