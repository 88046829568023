import React, { useState } from 'react';
import './Home.css';
import Footer from '../Footer/Footer';
import SideBar from '../SideBar/SideBar';
import { Outlet } from 'react-router-dom';
import { constants } from '../../utils/constants';
import { useLoadScript } from '@react-google-maps/api';
import { useScreenType } from '../../utils/commonMethods';
import Header from '../Header/Header';
import GeneratingReport from '../GeneratingReport/GeneratingReport';

const Home = () => {
  const [showGeneratingReport, setShowGeneratingReport] = useState(false);
  const isMobile = useScreenType();
  const url = window.location.href;
  const libraries = ['places', 'drawing'];

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: constants.googleMapsApiKey,
    libraries,
  });

  if (loadError) return '';
  if (!isLoaded) return '';

  return (
    <div className="home-container">
      {localStorage.getItem('role') !== "carto" ? <Header /> : null}
      {showGeneratingReport ? (
        <GeneratingReport />
      ) : (
        <Outlet context={{ setShowGeneratingReport }} />
      )}
    </div>
  );
};

export default Home;
