import React, { forwardRef, useImperativeHandle, useState } from 'react';
import './Loader.css';
import LoaderImage from  "../../assets/loader.gif"

function Loader(props, ref) {
  const [loading, setLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      start: () => setLoading(true),
      stop: () => setLoading(false),
      isLoading: () => loading >= 1,
    }),
    []
  );

  if (loading) {
    return (
      // <div>Loading</div>
      <div className="loader-container">
        <img src={LoaderImage} alt="Loader" />
      </div>
    );
  } else return null;
}

export default forwardRef(Loader);
