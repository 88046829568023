import { apiTokenQueries } from "../../utils/ApiCall";
import { SUCCESS_STATUS_CODE, FAILURE_STATUS_CODE } from "../../utils/constants";
import toast from "react-hot-toast";

const getNearmapImage = (
  id,
  nearmapcoords,
  setNearmapSurveyData
) => {
  console.log("popopss", nearmapcoords)
  if (nearmapcoords[0][0] && nearmapcoords[0][1]) {
    apiTokenQueries("GET", `order/prop-id/${id}/image/coverage`).then((res)=>{
      if(SUCCESS_STATUS_CODE.includes(res.status)){
          setNearmapSurveyData(res.data.data);
      }
      else if(FAILURE_STATUS_CODE.includes(res.status)){
      toast.error(res.data.errorDesc)
      }     
  })
  }
};

export default getNearmapImage;
