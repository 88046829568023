import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import classes from "./OrderList.module.css";
import DashBoardStatusContainer from "../../components/DashBoardStatusContainer/DashBoardStatusContainer";
import FilterSearch from "../../components/FilterSearch/FilterSearch";
import PopupBox from "../../components/PopUp/PopUp";

function OrderList() {
  const location = useLocation();

  const [status, setStatus] = useState("");
  const [addressSearch, setAddressSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showFilterPopUp, setShowFilterPopUp] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState([]); // Store filtered orders

  useEffect(() => {
    // Access query parameters from the location object
    const searchParams = new URLSearchParams(location.search);

    // Get the 'status' query parameter and set the state
    const statusParam = searchParams.get("status");
    if (statusParam) {
      setStatus(statusParam);
    }

    // Handle other query parameters if needed
  }, [location.search]);

  const filterOrders = (filterValues) => {
    const orders = [
      { id: 1, status: "draft", date: "2024-08-01", address: "123 Oak Valley" },
      {
        id: 2,
        status: "in_progress",
        date: "2024-08-02",
        address: "456 Maple Street",
      },
      {
        id: 3,
        status: "measurement_completed",
        date: "2024-08-03",
        address: "789 Pine Avenue",
      },
      // Add your orders data here
    ];

    console.log("Filtering for status:", filterValues.status);

    const filtered = orders.filter((order) => {
      let statusMatch = true;
      let startDateMatch = true;
      let endDateMatch = true;
      let addressMatch = true;

      if (filterValues.status) {
        statusMatch = order.status === filterValues.status;
        console.log(
          `Status match (${order.status} === ${filterValues.status}):`,
          statusMatch
        );
      }

      if (filterValues.startDate) {
        startDateMatch =
          new Date(order.date) >= new Date(filterValues.startDate);
        console.log(
          `Start date match (${order.date} >= ${filterValues.startDate}):`,
          startDateMatch
        );
      }

      if (filterValues.endDate) {
        endDateMatch = new Date(order.date) <= new Date(filterValues.endDate);
        console.log(
          `End date match (${order.date} <= ${filterValues.endDate}):`,
          endDateMatch
        );
      }

      if (filterValues.addressSearch) {
        addressMatch = order.address
          .toLowerCase()
          .includes(filterValues.addressSearch.toLowerCase());
        console.log(
          `Address match (${order.address.toLowerCase()} includes ${filterValues.addressSearch.toLowerCase()}):`,
          addressMatch
        );
      }

      return statusMatch && startDateMatch && endDateMatch && addressMatch;
    });

    console.log("Filtered Orders:", filtered);
    setFilteredOrders(filtered);
  };

  const handleApplyFilter = (filterValues) => {
    console.log("Applying filter with values:", filterValues);
    if (filterValues.status) {
      setStatus(filterValues.status);
    } else {
      setStatus(""); // Reset status filter if empty
    }

    if (filterValues.startDate) {
      setStartDate(filterValues.startDate);
    } else {
      setStartDate(null); // Reset start date filter if empty
    }

    if (filterValues.endDate) {
      setEndDate(filterValues.endDate);
    } else {
      setEndDate(null); // Reset end date filter if empty
    }

    filterOrders(filterValues); // Apply the filter when the user clicks "Apply"
  };

  useEffect(() => {
    filterOrders({ status, startDate, endDate, addressSearch });
  }, [status, startDate, endDate, addressSearch]);

  return (
    <div className={classes.OuterContainer}>
      {showFilterPopUp && (
        <PopupBox
          setShowFilterPopUp={setShowFilterPopUp}
          onApplyFilter={handleApplyFilter}
          initialStatus={status}
        />
      )}
      <DashBoardStatusContainer status={status} setStatus={setStatus} />
      <FilterSearch
        status={status}
        addressSearch={addressSearch}
        setAddressSearch={setAddressSearch}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        setShowFilterPopUp={setShowFilterPopUp}
        startDate={startDate}
        endDate={endDate}
      />
      {/* Display the filtered orders */}

     {/* <div>
        {filteredOrders.length > 0 ? (
          filteredOrders.map((order) => (
            <div key={order.id}>
              <p>ID: {order.id}</p>
              <p>Address: {order.address}</p>
              <p>Status: {order.status}</p>
              <p>Date: {order.date}</p>
            </div>
          ))
        ) : (
          <p>No orders found.</p>
        )}
      </div>*/}
    </div>
  );
}

export default OrderList;
