import { apiTokenQueries } from "../../utils/ApiCall";
import { SUCCESS_STATUS_CODE, FAILURE_STATUS_CODE } from "../../utils/constants";
import toast from "react-hot-toast";

const getLayersData = (
  id,
  viewId,
  setLayersData,
  setUploadedLayer
) => {
  apiTokenQueries("GET", `order/prop-id/${id}/view/${viewId}/layer/info`).then((res)=>{
    if(SUCCESS_STATUS_CODE.includes(res.status)){
        const featureData = res.data.data
        setLayersData(featureData)
        setUploadedLayer([])

    }
    else if(FAILURE_STATUS_CODE.includes(res.status)){
    toast.error(res.data.errorDesc)
    }     
})
};

export default getLayersData;
