import axios from 'axios';
import React from 'react';
import './LandingPage.css';
import { useState } from 'react';
import { useEffect } from 'react';
import '@reach/combobox/styles.css';
import Tooltip from '@mui/material/Tooltip';
import AuthToken from '../../context/AuthToken';
import HouseIcon from '@mui/icons-material/House';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { useLoader } from '../../context/LoaderProvider';
import { ALL_PROPERTIES_URL } from '../../helper/ApiUrl';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import AddressSearch from '../../components/AddressSearch/AddressSearch';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

const LandingPage = () => {
  const token = AuthToken();
  const [completedProp, setCompletedProp] = useState([]);
  const [draft, setDraft] = useState([]);
  const { startLoader, stopLoader } = useLoader();


  return (
    <div className="landing-main-container">
      <div className="landing-right-container">
        <div className="landing-search-bar">
          <AddressSearch />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
