import React from "react";
import './OuterForm.css'

export default function OuterForm(props){
    
    return(
    <div className="form-container">

    {props.children}
    </div>
    )
}