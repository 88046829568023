import Map from 'ol/Map';
import axios from 'axios';
import View from 'ol/View';
import { Feature } from 'ol';
import './OpenLayersPage.css';
import XYZ from 'ol/source/XYZ';
import { Style } from 'ol/style';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import { toLonLat } from 'ol/proj';
import { transform } from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { useNavigate } from 'react-router-dom';
import { defaults } from 'ol/control/defaults';
import AuthToken from '../../context/AuthToken';
import '../OpenLayersIdPage/OpenLayersIdPage.css';
import markerIcon from '../../assets/location.svg';
import CreateIcon from '@mui/icons-material/Create';
import { useLoader } from '../../context/LoaderProvider';
import { CREATE_PROPERTY_URL } from '../../helper/ApiUrl';
import React, { useEffect, useRef, useState } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { useAddressContext } from '../../context/AddressContext';
// import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { ReactComponent as CheckIcon } from '../../assets/Check.svg';
import AlternateOrderPage from '../AlternateOrderPage/AlternateOrderPage';
import { ReactComponent as PropertyIcon } from '../../assets/Property.svg';
import { SUCCESS_STATUS_CODE, FAILURE_STATUS_CODE } from '../../utils/constants';
import { apiTokenQueries } from '../../utils/ApiCall';
import toast from 'react-hot-toast';
import { Button } from '@mui/material';
import GetAuthToken from '../../utils/GetToken';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const OpenLayersPage = () => {
  const markerRef = useRef();
  markerRef.current = new LayerGroup({
    title: 'Marker',
    layers: [],
  });
  const navigate = useNavigate();
  const mapElement = useRef();
  const [map, setMap] = useState();
  const { address, latLng, setAddress } = useAddressContext();
  const [updatedAddress, setUpdatedAddress] = useState('');
  const [center, setCenter] = useState([0, 0]);
  const [showToggleOn, setShowToggleOn] = useState(false);
  const[hasAccess, setHasAccess] = useState(true)
  const {
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ['us', 'CA'] },
    },
  });

  const initializeMap = () => {
    if (address) {
      const initialMap = new Map({
        view: new View({
          zoom: 19,
        }),
        layers: [
          new TileLayer({
            source: new XYZ({
              url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
            }),
            visible: true,
            title: 'Satellite',
          }),
          new TileLayer({
            source: new XYZ({
              url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
            }),
            visible: false,
            title: 'Road',
          }),
        ],
        target: mapElement.current,
        keyboardEventTarget: document,
        controls: defaults({
          zoom: false,
          attribution: false,
          rotate: false,
        }),
      });
      setMap(initialMap);
      setCenter(latLng);
    }
  };

  useEffect(() => {
    if (!address) {
      navigate(`/home`);
    }
  
    initializeMap();
  }, [latLng]);
  

  const setProperty = () => {
    if (map) {
      map
        .getView()
        .animate(
          { zoom: 19 },
          { duration: 1000 },
          { center: transform(center, 'EPSG:4326', 'EPSG:3857') }
        );
      if (map.getLayers().getArray().length > 1) {
        map.getLayers().forEach((layer) => {
          if (layer.get('title') && layer.get('title') === 'Marker') {
            map.removeLayer(layer);
          }
        });
      }
      map.addLayer(markerRef.current);
      const iconFeature = new Feature({
        geometry: new Point(transform(center, 'EPSG:4326', 'EPSG:3857')),
      });
      const iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: markerIcon,
        }),
      });
      iconFeature.setStyle(iconStyle);
      const vectorSource = new VectorSource({
        features: [iconFeature],
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      markerRef.current.getLayers().push(vectorLayer);
    }
  };

  useEffect(() => {
    setProperty();
  }, [center]);

  if (map) {
    map.on('click', function (e) {
      setCenter(toLonLat(e.coordinate));
    });
  }



  const handleZoomIn = () => {
    map.getView().animate({
      zoom: map.getView().getZoom() + 1,
      duration: 250,
    });
  };

  const handleZoomOut = () => {
    map.getView().animate({
      zoom: map.getView().getZoom() - 1,
      duration: 250,
    });
  };

  const handleLayerTypeMap = () => {
    map.getLayers().forEach((layer) => {
      if (layer.get('title') && layer.get('title') === 'Satellite') {
        layer.values_.visible = false;
      } else if (layer.get('title') && layer.get('title') === 'Road') {
        layer.values_.visible = true;
      }
    });
    map.getView().animate({});
  };

  const handleLayerTypeSatellite = () => {
    map.getLayers().forEach((layer) => {
      if (layer.get('title') && layer.get('title') === 'Road') {
        layer.values_.visible = false;
      } else if (layer.get('title') && layer.get('title') === 'Satellite') {
        layer.values_.visible = true;
      }
    });
    map.getView().animate({});
  };

  const toggleShowBtn = () => {
    setShowToggleOn(!showToggleOn)
  };

  const confirmProperty = () =>{
    const data = {"address" :address, "lat" :  center[1], "long" : center[0]}
    const headers = {"Authorization" : GetAuthToken}
    apiTokenQueries("POST", "order/", data).then((res)=>{
        if(SUCCESS_STATUS_CODE.includes(res.status)){
            console.log(res.data)
            const orderId = res.data.data.order_id
            navigate(`/order/${orderId}`)
            
        }
        else if(FAILURE_STATUS_CODE.includes(res.status)){
          toast.error(res.data.errorDesc)
        }     
      })
    
  }

  if (!address) return <AlternateOrderPage />;
  else
    return (
      <>
        <div className="ol-order-page">
          {/* <ProgressBar step={1} /> */}
          
          
        {/* <button onClick={handleToggle} className={classes.ToggleButton}>
            {isExpanded ? <RemoveIcon /> : <AddIcon />}
          </button> */}
          {hasAccess && <>{showToggleOn === false ? <>
          
        <div className="MapLeftBarContainerOl">
        <div className="toggle-bar-ol" style={{ backgroundColor: '#3ba364' ,display: "none" }}>
         <div className='switch-btn-ol-toggle' onClick={toggleShowBtn}><KeyboardArrowUpIcon></KeyboardArrowUpIcon></div> 
        </div>
        <div className="IncorrectPropertyMessage">
        
              <h2>Pin on Incorrect Property</h2>
              <p>
                If you believe the pin is on the wrong property, please follow these steps:
              </p>
              <ul>
                <li>Spot the correct property on the screen.</li>
                <li>Click on the map to drop a pin on the correct location.</li>
                <li>Click on the "Confirm Address" button to update the address.</li>
              </ul>
             
            </div>
          <div className="ConfirmPropertyContainerol">
            <Button onClick={confirmProperty}>
              Confirm Property
            </Button>
                
          </div>
          
        </div>
      
      </>
          :
          <div className="toggle-bar-ol" style={{ backgroundColor: '#3ba364'  }}><div className='switch-btn-ol-toggle' onClick={toggleShowBtn}><KeyboardArrowUpIcon></KeyboardArrowUpIcon></div> : 
    
          </div>  
          }  </>   }  
         
          <div ref={mapElement}  className="main-ol-container-ol">
          
            <div className="map-buttons">
              <div className="map-type-buttons">
                <button onClick={() => handleLayerTypeMap(map)}>Map</button>
                <button onClick={() => handleLayerTypeSatellite(map)}>
                  Satellite
                </button>
              </div>
              {/* 
              <div className="map-zoom-buttons">
                <button onClick={() => handleZoomIn(map)}>+</button>
                <button onClick={() => handleZoomOut(map)}>-</button>
              </div>
              */}

            </div>
          </div>
        </div>
      </>
    );
};

export default OpenLayersPage;
