import React from "react";
import "./AlternateOrderPage.css";
import AddressSearch from "../../components/AddressSearch/AddressSearch";

const AlternateOrderPage = () => {
  return (
    <div className="alternate-order-container">
      <div className="alternate-search-bar">
        <AddressSearch />
      </div>
    </div>
  );
};

export default AlternateOrderPage;
