import React from 'react'
import './SignIn.css'

import OuterForm from '../../components/OuterForm/OuterForm';
import SignInForm from '../../components/SignInForm/SignInForm';

function SignIn() {
  return (
    <div className="outer-container">
            <OuterForm>
             <SignInForm />
            </OuterForm>

        </div>
  )
}

export default SignIn