import React, { useContext, useState, createContext } from "react";

const EmailContext = createContext();

export function EmailContextProvider({ children }) {
  const [email, setEmail] = useState("");

  return (
    <EmailContext.Provider
      value={{
        email,
        setEmail,
      }}
    >
      {children}
    </EmailContext.Provider>
  );
}

export function useEmailContext() {
  const context = useContext(EmailContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
