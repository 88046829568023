import * as turf from "@turf/turf";
import GeoJSON from "ol/format/GeoJSON";
import { Draw, Snap } from "ol/interaction";
import parcelStyle from "../OLStyles/ParcelStyle";
import calculateArea from "../OLFeatures/CalculateArea";
import removeAllInteraction from "../OLFeatures/RemoveInteraction";
import toast from "react-hot-toast";

const drawPolygon = (
  map,
  parcelRef,
  polygonList,
  setZoomLayer,
  setParcelEdit,
  setPolygonList,
  setParcelStyle,
) => {
  var source;

  map.getLayers().forEach((layer) => {
    if (layer.get("title") && layer.get("title") === "Parcel") {
      source = layer.getLayers().getArray()[0].getSource();
    }
  });

  const draw = new Draw({
    source: source,
    type: "Polygon",
  });

  const snap = new Snap({
    source: source,
  });

  setZoomLayer(false);
  setParcelEdit(false);
  removeAllInteraction(map);
  map.addInteraction(draw);
  setParcelStyle(parcelStyle);
  document.body.classList.remove('cut-cursor');
  document.body.classList.remove("delete-cursor");

  draw.on("drawend", function (e) {
    map.removeLayer(parcelRef.current);
    let parser = new GeoJSON();
    let drawnFeatures = parser.writeFeaturesObject([e.feature]);
    var feature = e.feature;
    var poly = feature.getGeometry().getCoordinates();
    if (feature.getGeometry().getType() === "Polygon") {
      var kinkedPoly = turf.polygon(poly);
      var unkinkedPoly = turf.unkinkPolygon(kinkedPoly);
      if (unkinkedPoly.features.length > 1) {
        toast.warning("Self intersecting polygon is not supported")
        return false;
      }
    }
    const parcelData = {
      id: polygonList.length
        ? parseInt(polygonList[polygonList.length - 1].id) + 1
        : parseInt(polygonList.length) + 1,
      data: [drawnFeatures.features[0].geometry.coordinates[0]],
      visible: true,
      measurement: calculateArea([
        drawnFeatures.features[0].geometry.coordinates[0],
      ]),
    };
    polygonList.push(parcelData);
    setPolygonList([...polygonList]);
  });
  if (polygonList.length > 0) {
    map.addInteraction(snap);
  }
};

export default drawPolygon;
