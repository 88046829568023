import {
  ORDER_URL,
  ADD_FEEDBACK,
  LAYERS_DATA_URL,
  UPLOAD_LAYERS_DATA_URL,
} from "../../helper/ApiUrl";
import JSZip from "jszip";
import axios from "axios";
import "./UploadOrderPage.css";
import { saveAs } from "file-saver";
import Switch from "@mui/material/Switch";
import Tooltip from '@mui/material/Tooltip';
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import FormGroup from "@mui/material/FormGroup";
import AuthToken from "../../context/AuthToken";
import React, { useState, useEffect } from "react";
import PublishIcon from "@mui/icons-material/Publish";
import DownloadIcon from "@mui/icons-material/Download";
import FormControlLabel from "@mui/material/FormControlLabel";
import UserPrompt from "../../components/UserPrompt/UserPrompt";
import { FEEDBACK_STATUS, TOAST_TYPE } from "../../utils/constants";
import { ReactComponent as CheckIcon } from "../../assets/CheckBlack.svg";
import { SUCCESS_STATUS_CODE, FAILURE_STATUS_CODE } from "../../utils/constants";
import toast from "react-hot-toast";
import { apiTokenQueries, API_URL } from "../../utils/ApiCall";



const UploadOrderPage = ({ handleError, handleSuccess }) => {
  const token = AuthToken()
  const { id } = useParams();
  const [files, setFiles] = useState({});
  const [jpgUrl, setjpgUrl] = useState();
  const [tiifUrl, setTiifUrl] = useState();
  const [parcelId, setParcelId] = useState();
  const [isActive, setIsActive] = useState();
  const [orderNote, setOrderNote] = useState();
  const [address, setAddress] = useState(null);
  const [resolved, setResolved] = useState(false);
  const [jpgLoaded, setJpgLoaded] = useState(true);
  const [tiifLoaded, setTiifLoaded] = useState(true);
  const [feedbackData, setFeedbackData] = useState([]);
  const [previewStatus, setPreviewStatus] = useState();
  const [promptUser, togglePromptUser] = useState(false);
  const [layerUploadData, setUploadLayerData] = useState([]);
  const [isPreviewApproved, setIsPreviewApproved] = useState();
  const [orderAttachments, setOrderAttachments] = useState([]);
  const [measureHoa, setMeasureHoa] = useState(false);
  const [excludeCurb, setExcludeCurb] = useState(false);
  const [includeFrontYard, setIncludeFrontYard] = useState(false);



  const getLayers = () => {
    const featur_url = `order/prop-id/${id}/feature`
    apiTokenQueries('GET',featur_url).then((res) => {
      if (SUCCESS_STATUS_CODE.includes(res.status)) {
        setUploadLayerData(res.data.data);
      } else if (FAILURE_STATUS_CODE.includes(res.status)) {
        toast.error(res.data.errorDesc);
      }
    });
  };

  const fetchImage = () => {

    const image_url =  `order/prop-id/${id}/image`

    apiTokenQueries('GET',image_url).then((res) => {
      if (SUCCESS_STATUS_CODE.includes(res.status)) {
        const data = res.data.data;
        if (data.length) {
          data.map((item) => {
            setIsActive(item.is_active);
            if (item.url) {
              setjpgUrl(item.url);
              setJpgLoaded(true);
            }
            if (item.tiif_url) {
              setTiifUrl(item.tiif_url);
              setTiifLoaded(true);
            }
          });
        }
      } else if (FAILURE_STATUS_CODE.includes(res.status)) {
        toast.error(res.data.errorDesc);
      }
    });
  };

  const downloadData = () => {
    axios
      .get(`${ORDER_URL}${id}/view`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        if (res.data.data) {
          const viewId = res.data.data[0].id;
          axios
            .get(`${ORDER_URL}${id}/view/${viewId}/layers`, {
              headers: {
                Authorization: `Token ${token}`,
                Accept: "application/json",
              },
            })
            .then((res) => {
              if (res.data.data) {
                const data = res.data.data;
                var zip = new JSZip();
                data.map((item) => {
                  zip.file(`${item.name}.json`, JSON.stringify(item.data));
                });
                zip.generateAsync({ type: "blob" }).then(function (content) {
                  saveAs(content, "geojson.zip");
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAddress = () => {

    const image_url =  `order/prop-id/${id}`

    apiTokenQueries('GET',image_url).then((res) => {
      if (SUCCESS_STATUS_CODE.includes(res.status)) {
        const data = res.data.data
        setParcelId(data.id);
        setAddress(data.address);
        setOrderNote(data.order_note);
        setOrderAttachments(data.attachments);
        setMeasureHoa(data.measure_hoa);
        setExcludeCurb(data.exclude_curb);
        
        setIncludeFrontYard(data.include_front_yard);

    
      } else if (FAILURE_STATUS_CODE.includes(res.status)) {
        toast.error(res.data.errorDesc);
      }
    });
  };

  useEffect(() => {
    getLayers();
    fetchImage();
    getAddress();
  }, []);

  const handleUpload = () => {
    const formData = new FormData();

    for (let key in files) {
      formData.append(`${key}`, files[key]);
    }

    const featur_url = `ops/order/${id}/view`

    apiTokenQueries('POST',featur_url, formData).then((res) => {
      if (SUCCESS_STATUS_CODE.includes(res.status)) {
        toast.success("Layers uploaded")
      } else if (FAILURE_STATUS_CODE.includes(res.status)) {
        toast.error(res.data.errorDesc);
      }
    });

    // axios
    //   .post(`${UPLOAD_LAYERS_DATA_URL}/${id}/view`, formData, {
    //     headers: {
    //       Authorization: `Token ${token}`,
    //       Accept: "application/json",
    //     },
    //   })
    //   .then((response) => {
    //     setIsPreviewApproved(false);
    //     handleSuccess(TOAST_TYPE.SUCCESS, `Layers Uploaded`);
    //   })
    //   .catch((error) => {
    //     handleError(error);
    //   });
  };

  const handleDownload = () => {

    const image_url =  `${API_URL}order/prop-id/${id}?download=true`

    axios
      .get(image_url, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        const fileName = `${parcelId}.geojson`;
        downloadBlob(response.data, fileName);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(
      new Blob([blob], { type: "application/json" })
    );
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    a.click();
  }

  const generateJpg = () => {
    setJpgLoaded(false);
    const image_url =  `order/prop-id/${id}/image`
    apiTokenQueries('POST',image_url).then((res) => {
      if (SUCCESS_STATUS_CODE.includes(res.status)) {
        setjpgUrl(res.data.url);
        setJpgLoaded(true);
        setIsActive(true);
      } else if (FAILURE_STATUS_CODE.includes(res.status)) {
        toast.error(res.data.errorDesc);
      }
    });
  };

  const generateTiif = () => {
    setTiifLoaded(false);

    const image_url =  `order/prop-id/${id}/image`
    apiTokenQueries('PUT',image_url).then((res) => {
      if (SUCCESS_STATUS_CODE.includes(res.status)) {
        const data = res.data.data;
        data.map((item) => {
          setTiifUrl(item.url);
        });
        setTiifLoaded(true);
        window.location.reload();
      } else if (FAILURE_STATUS_CODE.includes(res.status)) {
        toast.error(res.data.errorDesc);
      }
    });
  };

  const getFeedbackData = () => {
    axios({
      url: `${ADD_FEEDBACK}${id}/feedback`,
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        if ("data" in res) {
          setFeedbackData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFeedbackData();
  }, [resolved]);


  const handleChangeIsActive = () => {
    axios({
      url: `${ORDER_URL}${id}/image/active`,
      method: "PUT",
      data: { is_active: !isActive },
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((response) => {
        setIsActive(!isActive);
        handleSuccess(TOAST_TYPE.SUCCESS, "Image status changed successfully.");
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const handleTogglePromptUser = () => {
    if (previewStatus) {
      if (isPreviewApproved)
        togglePromptUser(true);
      // else handleSuccess(TOAST_TYPE.WARNING, "Mark order as previewed to submit the order");
    }
    else togglePromptUser(true);
  }

  return (
    <div className="upload-order-page">
      <div className="upload-order-page-left">
        <div className="upload-order-page-left-one">
          <p>{address}</p>
        </div>
        <div className="upload-order-page-left-two">
          <div className="upload-order-page-left-two-left">
            <p>
              <label htmlFor="user-notes">User Notes</label>
            </p>
            <div className="order-notes-textarea">{orderNote}</div>
            <p>
              <label htmlFor="user-notes">Measure HOA</label>
            </p>
            <div className="order-notes-textarea">
              {measureHoa ? "Measure HOA" : "No Measure HOA"}
            </div>
            <div className="order-notes-textarea">
              {excludeCurb ? "Exclude Curb" : "NO Exclude Curb"}
            </div>
            <div className="order-notes-textarea">
              {includeFrontYard ? "Include Front Yard" : "No Include Front Yard"}
            </div>
          </div>
          {/* <div className="upload-order-page-left-two-right">
            <p>Download Attachments</p>
            <div className="download-attachment">
              <div>
                <span>1</span>
              </div>
              <div>
                <span>Feedback</span>
                <NotificationImportantIcon className="download-attachment-icon" />
              </div>
            </div>
          </div> */}
        </div>
        <div className="upload-order-page-left-three">
          <p>Order Attachments</p>
          <div>
            {orderAttachments &&
              orderAttachments.map((attachment, index) => {
                return (
                  <div className="order-attachment-cont" key={index}>
                    <div className="order-attachment-inner-cont">
                      {
                        <div>
                          <span></span>
                        </div>
                      }
                      <div>
                        <span></span>
                      </div>
                    </div>
                    <a style={{ textDecoration: "none" }} href={attachment}>
                      <div className="generate-button">Download</div>
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="upload-order-page-left-five">
          <div className="jpg-main-container">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
               <div>Jpg Download</div>
              <FormGroup>
                <FormControlLabel
                  label="HD Image"
                  control={
                    <Switch
                      label="HD Image"
                      checked={isActive}
                      onChange={handleChangeIsActive}
                      color="success"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                />
              </FormGroup> 
            </div>
             <div className="jpg-container">
              <div className="jpg-link">{jpgUrl}</div>
              {jpgUrl && jpgLoaded ? (
                <a href={jpgUrl}>
                  <div className="generate-button">Download</div>
                </a>
              ) : jpgLoaded ? (
                <div onClick={generateJpg} className="generate-button">
                  Generate
                </div>
              ) : (
                <div className="generate-button loader">Loading...</div>
              )}
            </div> 
          </div>
           <div className="tiif-main-container">
            <div>Tiif Download</div>
            <div className="tiif-container">
              <div className="tiif-link">{tiifUrl}</div>
              {tiifUrl && tiifLoaded ? (
                <a href={tiifUrl}>
                  <div className="generate-button">Download</div>
                </a>
              ) : tiifLoaded ? (
                <div
                  onClick={jpgUrl && generateTiif}
                  className={
                    jpgUrl ? "generate-button" : "generate-button loader"
                  }
                >
                  Generate
                </div>
              ) : (
                <div className="generate-button loader">Loading...</div>
              )}
            </div>
          </div> 
        </div>
        <div className="upload-order-page-left-four">
          {/* <div className="upload-order-page-left-four-left">
            <p>Carto Logs</p>
            <div>
              <textarea />
            </div>
          </div> */}
          <div className="upload-order-page-left-four-right">
            {/* <div>
              <button className="carto-preview-btn">
                <VisibilityOutlinedIcon className="carto-preview-icon" />
                <span>Preview</span>
              </button>
            </div>
            <hr /> */}
            <div>
              {/* <button className="carto-download-btn">
                <DownloadIcon className="carto-download-icon" />
                <span>Download Image</span>
              </button> */}
              <button onClick={handleDownload} className="carto-download-btn">
                <span>Download Parcel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="upload-order-page-right">
        <div className="upload-order-page-right-one">
          <p>Upload Layers</p>
          <div className="upload-layers">
            <div className="upload-layers-one">
              {layerUploadData &&
                layerUploadData.map((feature) => {
                  return (
                    <div className="upload-layers-one-outter" key={feature.id}>
                      <label htmlFor={feature.id}>
                        <AddIcon className="upload-layers-add-icon" />
                        <span>{feature.name}</span>
                      </label>
                      <input
                        required
                        type="file"
                        id={feature.id}
                        onChange={(e) =>
                          setFiles({
                            ...files,
                            [feature.id]: e.target.files[0],
                          })
                        }
                      />
                    </div>
                  );
                })}
            </div>
            <div className="upload-layers-two">
              <div className="upload-layers-two-left">
                <span>{layerUploadData.length} Features</span>
              </div>
              <div className="upload-layers-two-right">
                <button
                  onClick={downloadData}
                  className="upload-layers-download-btn"
                >
                  <span>Download older data</span>
                </button>
                <button
                  onClick={handleUpload}
                  className="upload-layers-upload-btn"
                >
                  <span>Upload Property Layers</span>
                </button>
                <a
                  target="_blank"
                  href={`/preview/order/${id}/krypton`}
                  className="upload-layers-upload-btn"
                >
                  <span>Preview order</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="upload-order-page-right-two">
          <div className="new-cacrto-log-two">
              <button
                onClick={handleTogglePromptUser}
                className="new-cacrto-log-submit-btn"
                style={!isPreviewApproved && previewStatus ? { opacity: "0.5" } : { opacity: "1" }}
              >
                <CheckIcon className="new-cacrto-log-submit-icon" />
                <span>Submit Order</span>
              </button>
          </div>
        </div> */}
      </div>
      <div className="user-prompt-container">
        {promptUser ? (
          <UserPrompt
            togglePromptUser={togglePromptUser}
            handleSuccess={handleSuccess}
            handleError={handleError}
          />
        ) : null}
      </div>
    </div>
  );
};

export default UploadOrderPage;
