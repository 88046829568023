import Feature from "ol/Feature";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { Polygon, LineString, MultiPoint, LinearRing } from "ol/geom";

const renderOrderLayer = (map, hex2rgba, orderRef, uploadedLayer) => {
  console.log("fromuploaded", uploadedLayer);

  uploadedLayer.map((layer) => {
    const features = [];
    if (layer.type === "polygon") {
      layer.data.map((polygon, hover_index) => {
        polygon.data.map((item, index) => {
          if (index === 0) {
            const style = new Style({});
            const feature = new Feature({
              geometry: new Polygon([item]),
            });
            const new_polygon = feature.getGeometry();
            polygon.data.map((hole, reIndex) => {
              if (reIndex > 0) {
                const linearRing = new LinearRing(hole);
                new_polygon.appendLinearRing(linearRing);
              }
            });
            feature.setProperties({
              id: polygon.id,
              hover_id: hover_index + 1,
              measurement: polygon.measurement,
              name: layer.name,
              measurement_type: "sqft",
              color: layer.fillColor,
            });
            if (polygon.visible) {
              feature.setStyle(null);
            } else if (!polygon.visible) {
              feature.setStyle(style);
            }
            features.push(feature);
          }
        });
      });
      const orderLayer = new VectorLayer({
        source: new VectorSource({
          features: features,
        }),
        style: new Style({
          stroke: new Stroke({
            color: hex2rgba(layer.fillColor, 1).toString(),
            width: layer.strokeWeight,
          }),
          fill: new Fill({
            color: hex2rgba(layer.fillColor, layer.fillOpacity).toString(),
          }),
          image: new CircleStyle({
            radius: layer.strokeWeight,
            fill: new Fill({
              color: layer.fillColor,
            }),
          }),
        }),
        title: layer.name,
        visible: layer.visible,
        id: layer.id,
      });
      orderRef.current.getLayers().push(orderLayer);
    }
  });

  uploadedLayer.map((layer) => {
    const features = [];
    if (layer.type === "line") {
      layer.data.map((line, index) => {
        const points = [];
        line.data.map((coordinates) => {
          points.push(coordinates);
        });
        const style = new Style({});
        const feature = new Feature({
          geometry: new LineString(points),
        });
        feature.setProperties({
          id: line.id,
          hover_id: index + 1,
          measurement: line.measurement,
          name: layer.name,
          measurement_type: "ft",
          color: layer.fillColor,
        });
        if (line.visible) {
          feature.setStyle(null);
        } else if (!line.visible) {
          feature.setStyle(style);
        }
        features.push(feature);
      });
      const orderLayer = new VectorLayer({
        source: new VectorSource({
          features: features,
        }),
        style: new Style({
          stroke: new Stroke({
            color: hex2rgba(layer.fillColor, 1).toString(),
            width: layer.strokeWeight,
          }),
          fill: new Fill({
            color: hex2rgba(layer.fillColor, layer.fillOpacity).toString(),
          }),
          image: new CircleStyle({
            radius: layer.strokeWeight,
            fill: new Fill({
              color: layer.fillColor,
            }),
          }),
        }),
        title: layer.name,
        visible: layer.visible,
        id: layer.id,
      });
      orderRef.current.getLayers().push(orderLayer);
    }
  });

  uploadedLayer.map((layer) => {
    const features = [];
    if (layer.type === "point") {
      layer.data.map((point, index) => {
        point.data.map((item) => {
          const style = new Style({});
          const feature = new Feature({
            geometry: new MultiPoint([item]),
          });
          feature.setProperties({
            id: point.id,
            hover_id: index + 1,
            name: layer.name,
            color: layer.fillColor,
            measurement_type: null,
            measurement: point.measurement,
          });
          if (point.visible) {
            feature.setStyle(null);
          } else if (!point.visible) {
            feature.setStyle(style);
          }
          features.push(feature);
        });
      });
      const orderLayer = new VectorLayer({
        source: new VectorSource({
          features: features,
        }),
        style: new Style({
          stroke: new Stroke({
            color: hex2rgba(layer.fillColor, 1).toString(),
            width: layer.strokeWeight,
          }),
          fill: new Fill({
            color: "rgba(255, 255, 255, 0)", // Transparent fill to make it hollow
          }),
          image: new CircleStyle({
            radius: 4,
            fill: new Fill({
              color: "rgba(255, 255, 255, 0)", // Transparent fill for hollow effect
            }),
            stroke: new Stroke({
              color: layer.fillColor, // Border color of the circle
              width: 2, // Width of the border
            }),
          }),
        }),
        title: layer.name,
        visible: layer.visible,
        id: layer.id,
      });
      orderRef.current.getLayers().push(orderLayer);
    }
  });

  map.getLayers().forEach(function (layer) {
    if (layer && layer.get("title") === "Order") {
      map.removeLayer(layer);
    }
  });
  orderRef.current.setZIndex(3);
  map.addLayer(orderRef.current);
};

export default renderOrderLayer;
