import React, { useState, useEffect, useRef } from 'react';
import classes from "./Header.module.css";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import { apiTokenQueries } from '../../utils/ApiCall';
import { SUCCESS_STATUS_CODE, FAILURE_STATUS_CODE } from '../../utils/constants';
import toast from 'react-hot-toast';
import CompanyLogo from "../../assets/MapNexa.png";
import Menu from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';



function Header() {
    const ref = useRef();
    const navigate = useNavigate();
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [propertyDropdownOpen, setPropertyDropdownOpen] = useState(false);
    const [settingsDropdownOpen, setSettingsDropdown] = useState(false);
    const [userIntitials, setUserIntitials] = useState(null);
    const [userName, setUserName] = useState(null);
    const [toggle, setToggle] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [feedBack, setFeedBack] = useState("");
   

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };
    const navigateDashboard = () => {
        navigate("/home");
    }

    useEffect(() => {
        apiTokenQueries("GET", `user/info`).then((res) => {
            if (SUCCESS_STATUS_CODE.includes(res.status)) {
                const viewData = res.data.data;
                let name = viewData.name;
                const orgName = viewData.organization;
                if (orgName === null || orgName === undefined) {
                    navigate('/organization');
                }
                let displayName = null;
                if (name.split(" ").length > 1) {
                    displayName = name.split(" ")[0][0] + name.split(" ")[1][0];
                }
                else {
                    displayName = name.split(" ")[0][0] + name.split(" ")[0][1];
                }

                setUserIntitials(displayName);
                setUserName(name.split(" ")[0]);

                console.log(viewData);
            }
            else if (FAILURE_STATUS_CODE.includes(res.status)) {
                toast.error(res.data.errorDesc);
            }
        })
    }, []);
    useEffect(() => {
        const checkIfClickedOutside = e => {
          // If the menu is open and the clicked target is not within the menu,
          // then close the menu
          if (toggle && ref.current && !ref.current.contains(e.target)) {
            setToggle(false)
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
     
    }
  }, [toggle])
  
  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showTooltip && ref.current && !ref.current.contains(e.target)) {
        setShowTooltip(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    
return () => {
  // Cleanup the event listener
  document.removeEventListener("mousedown", checkIfClickedOutside)
 
}
}, [showTooltip])
  

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    }

    const togglePropertiesDropdown = (delay = 300) => {
        setPropertyDropdownOpen(!propertyDropdownOpen);
        if (!propertyDropdownOpen) {
            // If dropdown is opened, do nothing
            return;
        }
        // Add a delay before closing the dropdown
        setTimeout(() => {
            setPropertyDropdownOpen(false);
        }, delay);
    }
    const toggleSettingsDropdown = () => {
        setSettingsDropdown(!settingsDropdownOpen);
    }

    const handleEditProfile = () => {
        navigate("/profile-setting");
    }

    const handleLogout = () => {
        window.localStorage.removeItem('authToken');
        navigate("/login");
    }
    

    return (
      <div className={classes.HeaderDiv}>
        <div className={classes.LeftContainer}>
          <div onClick={navigateDashboard} className={classes.CompanyHeading}>
            <img
              src={CompanyLogo}
              alt="CompanyLogo"
              className={classes.CompanyLogo}
            />
          </div>
          <nav className={classes.HeadingOptions}>
            <div
              className={classes.DropDownOption}
              onMouseEnter={togglePropertiesDropdown}
              onMouseLeave={togglePropertiesDropdown}
            >
              {" "}
              Properties <ArrowDropDownIcon />
              {propertyDropdownOpen && (
                <div className={classes.PropertiesDropdown}>
                  <div
                    className={classes.PropertiesOption}
                    onClick={() => navigate("/dashboard")}
                  >
                    Measurement
                  </div>

                  <div
                    className={classes.PropertiesOption}
                    onClick={() => navigate("/dashboard?status=measurement_completed")}
                  >
                    Completed 
                  </div>
                  <div
                    className={classes.PropertiesOption}
                    onClick={() => navigate("/dashboard?status=in_progress")}
                  >
                    In Progress
                  </div>
                  <div
                    className={classes.PropertiesOption}
                    onClick={() => navigate("/dashboard?status=draft")}
                  >
                    Draft
                  </div>
                </div>
              )}
            </div>
            <div
              className={classes.DropDownOption}
              onMouseEnter={toggleSettingsDropdown}
              onMouseLeave={toggleSettingsDropdown}
            >
              Settings <ArrowDropDownIcon />
              {settingsDropdownOpen && (
                <div className={classes.SettingsDropDown}>
                  <div
                    className={classes.SettingsOption}
                    onClick={() => navigate("/setting/user")}
                  >
                    My Profile
                  </div>
                  <div
                    className={classes.SettingsOption}
                    onClick={() => navigate("/setting/org")}
                  >
                    Org Settings
                  </div>
                  <div
                    className={classes.SettingsOption}
                    onClick={() => navigate("/setting/credit?page=1")}
                  >
                    Credit Management
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                position: "relative",
                display: "inline-block",
                marginLeft: "2%",
              }}
            >
              <div
                onClick={toggleTooltip}
                style={{ cursor: "pointer", marginLeft: "8px" }}
              >
                <span
                  className={classes.DropDownOption}
                  role="img"
                  aria-label="help"
                >
                  <HelpOutlineIcon /> Help
                </span>
              </div>
            </div>
          </nav>
        </div>
        <div className={classes.RightContainer}>
          <NotificationsIcon className={classes.notification} />
          <div
            className={classes.userNameContainer}
            onMouseEnter={toggleDropdown}
            onMouseLeave={toggleDropdown}
          >
            <div className={classes.UserThumbNail}>
              {userIntitials}
              {isDropdownOpen && (
                <div className={classes.UserDropdown}>
                  {/* <div onClick={handleEditProfile}>
                                    <EditIcon fontSize="small" /> Edit Profile
                                </div> */}
                  <div onClick={handleLogout}>
                    <LogoutIcon fontSize="small" /> Logout
                  </div>
                </div>
              )}
            </div>
            {/* {userName}*/}
            <ArrowDropDownIcon />
          </div>
        </div>
        <div className={classes.mobile}>
          <button
            onClick={() => setToggle(!toggle)}
            class="ToggleButtonHeader $ {toggle? 'on' : 'off'}"
          >
            {toggle ? (
              <Close style={{ color: "white" }} />
            ) : (
              <Menu style={{ color: "white" }} />
            )}
          </button>
          {toggle && (
            <ul
              className={classes.list}
              ref={ref}
              onClick={() => setToggle(!toggle)}
            >
              <div
                className={classes.MobileOption}
                onClick={() => navigate("/dashboard?status=all")}
              >
                Measurement
              </div>
              <div
                className={classes.MobileOption}
                onClick={() =>
                  navigate("/dashboard?status=measurement_completed")
                }
              >
                Completed
              </div>
              <div
                className={classes.MobileOption}
                onClick={() => navigate("/dashboard?status=in_progress")}
              >
                In Progress
              </div>
              <div
                className={classes.MobileOption}
                onClick={() => navigate("/dashboard?status=draft")}
              >
                Draft
              </div>
              <hr></hr>
              <div
                className={classes.MobileOption}
                onClick={() => navigate("/setting/user")}
              >
                My Profile
              </div>
              <div
                className={classes.MobileOption}
                onClick={() => navigate("/setting/org")}
              >
                Org Settings
              </div>
              <div
                className={classes.MobileOption}
                onClick={() => navigate("/setting/credit?page=1")}
              >
                Credit Management
              </div>
              <hr></hr>
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  marginLeft: "2%",
                }}
              >
                <div
                  onClick={toggleTooltip}
                  style={{ cursor: "pointer", marginLeft: "8px" }}
                >
                  <span
                    className={classes.DropDownOption}
                    role="img"
                    aria-label="help"
                  >
                    <HelpOutlineIcon /> Help
                  </span>
                </div>
              </div>
              <div
                className={classes.MobileOptionLogout}
                onClick={handleLogout}
              >
                <LogoutIcon className={classes.LogoutIcon} fontSize="small" />{" "}
                Logout
              </div>
            </ul>
          )}
        </div>
        {showTooltip && (
          <div
            className={classes.feedbackForm}
            ref={ref}
            style={{
              position: "absolute",
              top: "40%",
              left: "32.5%",
              transform: "translateX(-15%)",
              backgroundColor: "#3ba364",
              color: "#fff",
              paddingTop: ".5%",
              paddingBottom: "1%",
              borderRadius: "4px",
              zIndex: "10000",
              width: "50%",
              height: "auto",
              textAlign: "center",
            }}
          >
            <Box>
              <Typography variant="h6">How can we help you ?</Typography>
              <TextareaAutosize
                onChange={(e) => {
                  setFeedBack(e.target.value);
                }}
                value={feedBack}
                placeholder="Enter your query..."
                minRows={4}
                maxRows={8}
                style={{
                  width: "90%",
                  marginBottom: "1%",
                  borderRadius: "5px",
                  padding: "8px",
                }}
              />
              <Button
                variant="contained"
                style={{ backgroundColor: "#14261c" }}
                onClick={() => {
                  const data = { description: feedBack };
                  apiTokenQueries("POST", `order/feedback`, data).then(
                    (res) => {
                      if (SUCCESS_STATUS_CODE.includes(res.status)) {
                        toast.success(
                          "Feedback received, Our team will get back to you"
                        );
                        setFeedBack("");
                        setShowTooltip(false);
                      } else if (FAILURE_STATUS_CODE.includes(res.status)) {
                        toast.error(
                          "System error. Please write us to info@mapnexa.com"
                        );
                      }
                    }
                  );
                }}
              >
                Submit
              </Button>
            </Box>
          </div>
        )}
      </div>
    );
}

export default Header;
