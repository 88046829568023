import React from 'react'
import OuterForm from '../../components/OuterForm/OuterForm'
import ResetPasswordForm from '../../components/ResetPasswordForm/ResetPasswordForm'

function ResetPassword() {
  return (
    <div className="outer-container">
            <OuterForm>
              <ResetPasswordForm />

            </OuterForm>

        </div>
  )
}

export default ResetPassword