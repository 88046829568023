// UserContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import AuthToken from "./AuthToken";
import { useNavigate } from "react-router-dom";
import { USER_INFO_URL } from "../helper/ApiUrl";

const UserContext = createContext();

export function UserContextProvider({ children }) {
  const token = AuthToken();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [selfCredits, setSelfCredits] = useState();
  const [organization, setOrganization] = useState();

  useEffect(() => {
    if (token) {
      axios
        .get(USER_INFO_URL, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: "application/json",
          },
        })
        .then((response) => {
          setUserInfo(response.data.data);
          setOrganization(response.data.data.organization);
          localStorage.setItem("role", response.data.data.role);
          if (response.data.data.organization === null) {
            navigate("/organization");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem("authToken");
            sessionStorage.removeItem("authToken");
            navigate("/");
          }
        });
    }
  }, [token, navigate]);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUserInfo,
        selfCredits,
        organization,
        setSelfCredits,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }
  return context;
}
