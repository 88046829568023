import React, { useState } from 'react';
import classes from './ForgetPasswordForm.module.css';
import EmailIcon from '@mui/icons-material/Email';
import { TextField, Button, Typography, Icon, Link } from '@mui/material';
import { apiTokenQueries } from '../../utils/ApiCall';
import { SUCCESS_STATUS_CODE, FAILURE_STATUS_CODE } from '../../utils/constants';
import toast from 'react-hot-toast';
import CompanyLogo from "../../assets/MapNexa.png"

function ForgetPasswordForm() {
  const [email, setEmail] = useState('');
  const [sendForgetPassword, setSendForgetPassword] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = { email: email };
    apiTokenQueries('POST', 'user/forgot-password', data).then((res) => {
      console.log(res);
      if (SUCCESS_STATUS_CODE.includes(res.status)) {
        if (res.data?.success) {
          setSendForgetPassword(false)
        }
      } else if (FAILURE_STATUS_CODE.includes(res.status)) {
        toast.error(res.data.errorDesc);
      }
    });
  };

  return (
    <div>
      {sendForgetPassword ? (
        <div className={classes.ForgotPasswordFormContainer}>
          <img src={CompanyLogo} alt="CompanyLogo" className="CompanyLogo" />
          <Typography className={classes.ForgotHeading}>Forget Password</Typography>
          <Typography className={classes.ForgotDescription}>
            Enter your Email address and we will send you a link to reset your password
          </Typography>
          <form className={classes.ForgotForm} onSubmit={handleSubmit}>
            <TextField
              className="form-input-container"
              variant="outlined"
              style={{ backgroundColor: '#FFFFFF' }}
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Icon className="input__icon">
                    <EmailIcon style={{ color: '#928989' }} />
                  </Icon>
                ),
              }}
              placeholder="Enter your Email Address"
            />
            <Button className={classes.SubmitBtn} type="submit" variant="contained">
              Submit
            </Button>
          </form>
          <Link className={classes.Back2Login} href="/login">
            &lt; Back to Login
          </Link>
        </div>
      ) : <div className={classes.ForgotPasswordFormContainer}>
      <img src="/img/error_outline.svg?url" alt="" />
      <Typography className={classes.ForgotHeading}>Forget Password</Typography>
      <Typography className={classes.ForgotDescription}>
        { `You should have received a verification link at your email address, ${email}. Please check your spam folder if you can't find it in your inbox.`}
      </Typography>
      <Link className={classes.Back2Login} href="/login">
        &lt; Back to Login
      </Link>
    </div>}
    </div>
  );
}

export default ForgetPasswordForm;
