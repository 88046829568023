import { transform } from "ol/proj";
import hoverEffect from "../OLInteractions/HoverInteraction";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import { Style } from "ol/style";
import Icon from "ol/style/Icon";
import markerIcon from "../../assets/location.svg";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

const initializeMarker = (
  map,
  center,
  hex2rgba,
  setHoverData,
  showPopup,
  markerRef,
  setHoverMapKey,
  setSelectedHoverFeature,
  elem,
  original=false
) => {
  if (map) {
    map
      .getView()
      .animate(
        { duration: 1000 },
        { center: transform(center, "EPSG:4326", "EPSG:3857") }
      );
    hoverEffect(map, hex2rgba, setHoverMapKey, elem,original);
    const iconFeature = new Feature({
      geometry: new Point(transform(center, "EPSG:4326", "EPSG:3857")),
    });
    const iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 46],
        src: markerIcon,
        anchorYUnits: "pixels",
        anchorXUnits: "fraction",
      }),
    });
    iconFeature.setStyle(iconStyle);
    const vectorSource = new VectorSource({
      features: [iconFeature],
    });
    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });
    markerRef.current.getLayers().push(vectorLayer);
    markerRef.current.setZIndex(2);
    map.addLayer(markerRef.current);
  }
};

export default initializeMarker;
