import React, {useState, useEffect} from 'react'
import classes from "./SubmitOrder.module.css"
import { Button } from '@mui/material'
import { toLonLat } from 'ol/proj';
import { apiTokenQueries } from '../../utils/ApiCall';
import { SUCCESS_STATUS_CODE, FAILURE_STATUS_CODE } from '../../utils/constants';
import toast from 'react-hot-toast';
import SubmitLoader from  "../../assets/loader.gif";
import InfoIcon from '@mui/icons-material/Info';
import Info from '@mui/icons-material/Info';


function SubmitOrder({parcelArea, featureList, featureSelected, polygonList, id, orderStatus, setOrderStatus, nearmapImageDate,setOrderConfirm, commonAreaPlace, curbStripPlace, frontYardPlace}) {
  const [cost, setCost] = useState(0.0)
  const [deliveryTime, setDeliveryTime] = useState(0.0)
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };


  
  const coordinatesToLatLong = () => {
    let parcelData = [];
    for (let i = 0; i < polygonList.length; i++) {
      const coordinate = [];
      let coords = polygonList[i].data;
      for (let j = 0; j < coords.length; j++) {
        const singlePolygon = coords[j];
        const singlePolygonData = [];
        for (let k = 0; k < singlePolygon.length; k++) {
          let lonLat = toLonLat(singlePolygon[k]);
          singlePolygonData.push(lonLat);
        }
        coordinate.push(singlePolygonData);
      }
      parcelData.push(coordinate);
    }
    
    return {"geometry" : {"type": "MultiPolygon", "coordinates": parcelData}};
  };

  



  useEffect(() => {
    let totalFeatures = featureList.length

    if (parcelArea <= 4){
      let propertyCost = ((Math.ceil(parcelArea) * (totalFeatures) * 0.3 ) + 2).toFixed(2)
      let propertyTime =   (Math.ceil(parcelArea/5) *0.5 * totalFeatures)
      if (nearmapImageDate !== "SATELLITE"){
        propertyTime =   (Math.ceil(parcelArea/5) *0.5 * (totalFeatures))
      }
      if (propertyTime < 2){
        propertyTime = 1.5
      }
      console.log(commonAreaPlace, curbStripPlace, frontYardPlace, "ooooo")
      if (commonAreaPlace){
        console.log(propertyCost)
        propertyCost= parseFloat(propertyCost) * 0.65;
        propertyCost = parseFloat(propertyCost).toFixed(2)
      }
      setCost(propertyCost)
      setDeliveryTime(propertyTime)

    }
    else{
      let propertyCost = ((Math.ceil(parcelArea) * (totalFeatures) * 0.2 ) + 2).toFixed(2)
      console.log(Math.ceil(parcelArea), totalFeatures, "ceilval")
      let propertyTime =   (Math.ceil(parcelArea/5) *0.5  * totalFeatures)
      if (nearmapImageDate !== "SATELLITE"){
        propertyTime =   (Math.ceil(parcelArea/5) *0.5 * (totalFeatures-1))
      }
      if (propertyTime < 2){
        propertyTime = 1.5
      }


      if (commonAreaPlace){
        console.log(propertyCost)
        propertyCost= parseFloat(propertyCost) * 0.65;
        propertyCost = parseFloat(propertyCost).toFixed(2)
      }
      
      setCost(propertyCost)
      setDeliveryTime(propertyTime)

    }
    
  }, [parcelArea, featureList, nearmapImageDate, commonAreaPlace]);

  const handleOrderSubmission = () =>{
    if (parcelArea <=0){
      toast.error("Please draw a parcel to place your order")
      return false
    }
    document.body.classList.remove('cut-cursor');
    document.body.classList.remove("delete-cursor");
    setOrderConfirm(true)
    const latLng = coordinatesToLatLong()
    const idList = [];

  }


 

  return (
    <div className={classes.SubmitOrderContainer}>
        <div className={classes.AreaContainer}>Local Area : <span className={classes.AnswerContainer}>{parcelArea} acres</span></div>
        <div className={classes.PriceContainer}>Price : <span className={classes.AnswerContainer}>USD ${cost}</span></div>
        <div className={classes.ETAContainer}>Estimated Processing Time : <span className={classes.AnswerContainer}>{deliveryTime} hours</span></div>
        <div className={classes.PlaceOrder}>
          {orderStatus === 'draft' ?
          <Button variant="contained" className={classes.PlaceOrderBtn} onClick={handleOrderSubmission}>
          Place Order
          </Button>

           : <img src = {SubmitLoader} alt='Order loader' className={classes.SubmitLoader}/>}

<div style={{ position: 'relative', display: 'inline-block', marginLeft:'2%'}}>
      {/* Icon */}
      <div
        onMouseEnter={toggleTooltip}
        onMouseLeave={toggleTooltip}
        style={{ cursor: 'pointer', marginLeft : "8px" }}
      >

        <span role="img" aria-label="exclamation" style={{ fontSize: '24px' }}>
        <InfoIcon/>
        </span>
      </div>

      {/* Tooltip */}
      {showTooltip && (
        <div className={classes.TooltipContainer}
         

               
            
          
        >
          <h4>Pricing</h4>
          <p>For properties less than 4 acre. Per feature price is 0.30$ and  a fixed price of 2$</p>
          <p>For properties more than 4 acre. Per feature price is 0.20$ and  a fixed price of 2$</p>
        </div>
      )}
    </div>
        
        </div>

        
       
    </div>
  )
}

export default SubmitOrder