import React, { useState, useEffect } from "react";
import classes from "./DashBoardStatusContainer.module.css";
import { useNavigate } from "react-router-dom";

function DashBoardStatusContainer({ status, setStatus }) {
  const navigate = useNavigate();

  const handleStatusClick = (newStatus) => {
    setStatus(newStatus);
  };

  useEffect(() => {
    // You can place logic here if needed when the status changes
  }, [status]);

  return (
    <div className={classes.StatusContainer}>
      <div className={classes.StatusTrack}>
        <div
          className={`${classes.SingleStatus} ${
            status === "all" ? classes.ActiveTab : ""
          }`}
          onClick={() => handleStatusClick("all")}
        >
          All Measurements
        </div>
        <div className={classes.SingleStatusBar}> | </div>
        <div
          className={`${classes.SingleStatus} ${
            status === "measurement_completed" ? classes.ActiveTab : ""
          }`}
          onClick={() => handleStatusClick("measurement_completed")}
        >
          Measurement Completed
        </div>
        <div className={classes.SingleStatusBar}> | </div>
        <div
          className={`${classes.SingleStatus} ${
            status === "in_progress" ? classes.ActiveTab : ""
          }`}
          onClick={() => handleStatusClick("in_progress")}
        >
          In Progress
        </div>
        <div className={classes.SingleStatusBar}> | </div>
        <div
          className={`${classes.SingleStatus} ${
            status === "draft" ? classes.ActiveTab : ""
          }`}
          onClick={() => handleStatusClick("draft")}
        >
          Draft
        </div>
        {/* Add other status elements and apply the class conditionally */}
      </div>
      <div
        onClick={() => {
          navigate("/home");
        }}
        className={classes.CreatePropertyContainer}
      >
        Create a new property
      </div>
    </div>
  );
}

export default DashBoardStatusContainer;